import "./HowItWorks.css";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";
import React, { useState } from "react";

const data = [
  {
    q: "How it works",
    a: "Once you're connected with your exchange account our platform will send buy and sell orders (signals) all automatically. And as long as you're subscribed the service will be active.",
  },
  {
    q: "Is it safe?",
    a: "Yes. It's as safe as your exchange security. Your API Key is encrypted when stored on our servers. AS the copy trading is happening through your exchange API we can only provide buy and sell signals to your trading account Nothing else. We can't withdraw any funds what so ever.",
  },
  {
    q: "Can I trade on my connected trading account?",
    a: "Nope. Could cause a lot of trouble if it interferes with same trade setups as we do. If you want to trade yourself then connect a funded sub account to our service or do your own trading on the sub account. Just don't trade from the account you've connected with.",
  },
  {
    q: "How much funding should I use?",
    a: "We recommend that you as a minimum have $500 on your trading account If you don't have enough funds you won't be trading as the exchanges require a minimum amount per trade.",
  },
];

const HowItWorks = () => {
  const [showIndex, setShowIndex] = useState(null);

  return (
    <div className="howItWorksBox">
      {data.map((qa, index) => (
        <div className="qaBox" key={index}>
          <div className="qBox">
            <p
              className="question"
              onClick={() => setShowIndex(showIndex === index ? null : index)}
            >
              {qa.q}
            </p>
            <button
              onClick={() => setShowIndex(showIndex === index ? null : index)}
            >
              <img
                alt=""
                className={`${showIndex === index && "rotate"}`}
                src={dropdownAngle}
              />
            </button>
          </div>
          {showIndex === index && <p className="answer">{qa.a}</p>}
        </div>
      ))}
    </div>
  );
};

export default HowItWorks;

import React from "react";
import TradeHistory from "../sharedPages/performance/TradeHistory";

const ClosedTrades = () => {
  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="">
          <TradeHistory />
        </div>
      </div>
    </div>
  );
};

export default ClosedTrades;

import "./MessageModal.css";
import { Form, Modal } from "react-bootstrap";
import { missingPopup } from "../../../../helpers/dataHelper/missingData";
import { toastify } from "../../../../helpers/toast/toastify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import greenCross from "../../../../assets/images/green-corss.svg";
import rightAngle from "../../../../assets/images/rightAnglePath.svg";
import useApi from "../../../../helpers/apiHelper/requestHelper";

const MessageModal = ({ handleClose, selectedUser = null, show }) => {
  const api = useApi();
  const user = useSelector((value) => value?.user?.user);

  const allSubscribers = user?.leaderCommunity?.participants.map(
    (participant) => participant.user
  );

  const [data, setData] = useState({ message: "", subject: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select members");
  const [vipMembers, setVipMembers] = useState([]);

  const getAllVipMembers = async () => {
    const response = await api("get", "vipMember/allVipMembers");
    setVipMembers(response?.data?.vipMembers);
  };

  const sendMessage = async () => {
    if (selectedOption === "Select members" && !selectedUser) {
      setIsLoading(false);
      return toastify("Users are missing.");
    }

    if (!data?.message) {
      setIsLoading(false);
      return missingPopup("Message");
    }
    if (!data?.subject) {
      setIsLoading(false);
      return missingPopup("Subject");
    }

    const response = await api("post", "users/sendMessage", {
      ...data,
      users: selectedUser
        ? [selectedUser]
        : selectedOption === "All subscribers"
        ? allSubscribers
        : vipMembers,
    });

    if (response?.status) {
      handleClose();
      setData({ message: "", subject: "" });
      setIsLoading(false);
      setSelectedOption("Select members");
      toastify("Emails sent successfully.", "success");
    } else {
      setIsLoading(false);
      toastify(response?.message);
    }
  };

  useEffect(() => {
    getAllVipMembers();
  }, []);

  return (
    <Modal
      centered
      className="messageModal"
      onHide={handleClose}
      show={show}
      size="lg"
    >
      <Modal.Body>
        <button type="button" onClick={handleClose} className="close-btn">
          <img src={greenCross} alt="cross" />
        </button>
        <div className="row">
          <div className="col-12">
            <p className="ModalTitle">Message Member(s)</p>
          </div>
        </div>
        <Form>
          {selectedUser ? (
            <input
              className="selectedEmail mb-30px"
              disabled
              type="text"
              value={selectedUser.email}
            />
          ) : (
            <div className="selectMembers">
              <div className="dropdown">
                <button
                  className="dropdown-btn"
                  onClick={() => setIsOpen((pre) => !pre)}
                  style={{
                    backgroundColor:
                      selectedOption === "Select members" ? "#f7f7f7" : "white",
                    borderColor:
                      selectedOption === "Select members"
                        ? "transparent"
                        : "#10b700",
                    color: selectedOption === "Select members" && "#9a9b9b",
                  }}
                  type="button"
                >
                  {selectedOption}{" "}
                  <img
                    alt=""
                    className={`arrow ${isOpen && "rotate"}`}
                    src={dropdownAngle}
                    style={{
                      opacity: selectedOption !== "Select members" && 1,
                    }}
                  />
                </button>
                {isOpen && (
                  <ul className="dropdown-menu">
                    <li>
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          setSelectedOption("All subscribers");
                        }}
                        type="button"
                      >
                        All subscribers
                        <img alt="Right Angle" src={rightAngle} />
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          setSelectedOption("VIP members");
                        }}
                        type="button"
                      >
                        VIP members <img alt="Right Angle" src={rightAngle} />
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          )}

          <FloatingTextFiled
            className="subjectInput mb-30px"
            forLabel="subjectInput"
            id="subjectInput"
            label="Subject"
            onChange={(e) => {
              setData((pre) => ({ ...pre, subject: e.target.value }));
            }}
            placeholder="Subject"
            type="text"
            value={data.subject}
          />

          <div className="messageInput mb-30px">
            <div className="form-floating">
              <textarea
                className="form-control fs15"
                id="messageInput"
                placeholder="Message"
                onChange={(e) => {
                  setData((pre) => ({ ...pre, message: e.target.value }));
                }}
                value={data.message}
              />
              <label htmlFor="messageInput">Message</label>
            </div>
          </div>

          <div className="text-end">
            <button
              className="SendBtn"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true);

                sendMessage();
              }}
              type="button"
            >
              Send
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;

import React from "react";
import Subscribe from "../sharedPages/copyTradeCm/Subscribe.js";

const SubscriptionAndBilling = ({ setCommunity }) => {
  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="">
          <Subscribe setCommunity={setCommunity} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAndBilling;

import "./AllMembers.css";
import { Button, Modal, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { simplifyTradingVolume } from "../../../../helpers/helper";
import { toastify } from "../../../../helpers/toast/toastify";
import { updateUser } from "../../../../redux/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import cancel from "../../../../assets/images/cancel.svg";
import cross from "../../../../assets/images/cross.svg";
import CustomPagination from "../../../../components/CustomPagination";
import DatePicker from "react-datepicker";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import MessageModal from "../MessageModal/MessageModal";
import NewCopyIcon from "../../../../assets/images/NewCopyIcon.svg";
import React, { useEffect, useRef, useState } from "react";
import rightAngle from "../../../../assets/images/rightAnglePath.svg";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import tierAction from "../../../../assets/images/tierAction.svg";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import vipArrow from "../../../../assets/images/vipArrow.svg";
import vipBellPng from "../../../../assets/images/vipBellPng.png";
import vipCheckMark from "../../../../assets/images/vipCheckMark.svg";
import BubbleSvgButton from "../../CommonComponents/BubbleSvgButton/BubbleSvgButton";
import SearchBar from "../../CommonComponents/SearchBar/SearchBar";

const AllMembers = ({ isNewVipAdded }) => {
  const user = useSelector((value) => value?.user?.user);

  const communityLeaderId = user?.leaderCommunity?._id || "";
  const participants = user?.leaderCommunity?.participants || [];
  const subscriptions = user?.leaderCommunity?.subscription || [];

  const allTradingCapitals = subscriptions
    .filter((tier) => tier.isActive)
    .map((subscription) => subscription.tradingCapital);

  const vipSubscription = subscriptions.filter((subscription) =>
    subscription.name.endsWith("_Vip")
  )[0];
  if (vipSubscription) allTradingCapitals.push("VIP");

  const [editEmailIndex, setEditEmailIndex] = useState(null);
  const [editExpiryDateIndex, setEditExpiryDateIndex] = useState(null);
  const [filteredParticipants, setFilteredParticipants] = useState([
    ...participants,
  ]);
  const [filteredVipMembers, setFilteredVipMembers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSendMessages, setIsSendMessages] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [page, setPage] = useState(1);
  const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState("All subscribers");
  const [selectedParticipant, setSelectedParticipant] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState({
    apiObj: {},
    show: false,
  });
  const [showDropdown, setShowDropdown] = useState(true);
  const [vipMembers, setVipMembers] = useState([]);

  const actionButtonRef = useRef(null);
  const actionDropdownRef = useRef(null);
  const api = useApi();
  const dispatch = useDispatch();
  const expiryDropdownRef = useRef(null);
  const filterButtonRef = useRef(null);
  const filterDropdownRef = useRef(null);

  const closeDropdownOnOutsideClick = (e) => {
    if (
      actionButtonRef.current &&
      !actionButtonRef.current.contains(e.target) &&
      actionDropdownRef.current &&
      !actionDropdownRef.current.contains(e.target)
    )
      setSelectedDropdownIndex(null);

    if (
      expiryDropdownRef.current &&
      !expiryDropdownRef.current.contains(e.target) &&
      !isMobile
    ) {
      setEditExpiryDateIndex(null);
      setShowDatePicker(false);
    }

    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(e.target) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(e.target)
    )
      setIsOpen(false);
  };

  const getAllVipMembers = async () => {
    const response = await api("get", "vipMember/allVipMembers");
    setFilteredVipMembers(response?.data?.vipMembers);
    setVipMembers(response?.data?.vipMembers);
  };

  const handleOptionSelection = (option) => {
    if (option === "All subscribers") {
      setFilteredParticipants([...participants]);
    } else if (["VIP members", "VIP"].includes(option)) {
      setFilteredVipMembers([...vipMembers]);
    } else if (option === "VIP pending") {
      setFilteredVipMembers(
        vipMembers.filter((member) =>
          member?.codeValidity === "Specific end date"
            ? !member?.isUsed && new Date(member?.endDate) > new Date()
            : !member?.isUsed
        )
      );
    } else {
      setFilteredParticipants(
        participants.filter((participant) => {
          const subscriptionId =
            participant?.user?.memberCommunities[0].subscription;
          const matchedSubscription = subscriptions.find(
            (subscription) => subscription._id === subscriptionId
          );
          if (matchedSubscription.tradingCapital === option) return true;
          return false;
        })
      );
    }

    setIsOpen(false);
    setSelectedOption(option);
  };

  const getSubscription = (participant) =>
    subscriptions.find(
      (subscription) =>
        subscription._id ===
        participant?.user?.memberCommunities?.[0]?.subscription
    );

  const getTradingCapital = (participant) => {
    const subscription = getSubscription(participant);
    const capital = subscription?.tradingCapital;
    return capital === "UNLIMITED" ? capital : `$${capital}`;
  };

  const getEndDate = (endDate) => {
    const endDateFormatted = new Date(endDate);
    const day = endDateFormatted.getDate().toString().padStart(2, "0");
    const month = (endDateFormatted.getMonth() + 1).toString().padStart(2, "0");
    const year = endDateFormatted.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const unsubscribeMember = async () => {
    const response = await api("post", "users/unsubscribe", {
      communityId: communityLeaderId,
      leader: true,
      sessionId: selectedSubscription,
      userId: selectedParticipant,
    });

    if (response?.status) {
      dispatch(updateUser(response?.data?.user));
      toastify("User unsubscribed successfully.", "success");
    } else {
      toastify(response?.error);
    }
  };

  const updateVipMember = async (recordId, updateFields) => {
    if (editEmailIndex && !newEmail) return toastify("Email can't be empty.");

    const comunityLeaderUrl = `${document.location.origin.replace(
      document.location.pathname,
      ""
    )}/community/${user?.leaderCommunity?.name?.split(" ")?.join("")}`;

    const res = await api("post", "vipMember/updateVipMember", {
      comunityLeaderUrl,
      recordId,
      updateFields,
    });

    await getAllVipMembers();

    setNewEmail("");

    toastify(`${res?.message}`, "success");
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!isNewVipAdded) getAllVipMembers();
  }, [isNewVipAdded]);

  const showExpiryDate = (recordId, expiryDateValue) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <div ref={expiryDropdownRef}>
        {!showDatePicker && (
          <div className="dropdown mb-3 mb-lg-0">
            <button
              className={`btn btn-secondary dropdown-toggle memberDropDown selected`}
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {expiryDateValue}
              <img
                alt=""
                src={vipArrow}
                style={{ transform: showDropdown && "rotate(180deg)" }}
              />
            </button>

            <ul className="dropdown-menu show">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setEditExpiryDateIndex(null);
                    updateVipMember(recordId, {
                      codeValidity: "Lifetime",
                      endDate: null,
                    });
                  }}
                >
                  <span>Lifetime</span>
                  <span>
                    <img alt="" src={rightAngle} />
                  </span>
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setShowDatePicker(true);
                  }}
                >
                  <span>Specific end date</span>
                  <span>
                    <img alt="" src={rightAngle} />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        )}

        {showDatePicker && (
          <div className="mamberDateSelectBox mb-3 mb-lg-0">
            <DatePicker
              autoFocus={true}
              className="form-control memberDropDown"
              minDate={tomorrow}
              onChange={(date) => {
                setEditExpiryDateIndex(null);
                setShowDatePicker(false);
                updateVipMember(recordId, {
                  codeValidity: "Specific end date",
                  endDate: date,
                });
              }}
              placeholderText="Select end date"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="allMemberBox">
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="fixedWidth1440">
              <div className="memberFilteDropdown mb-40px">
                <div className="dropdown" ref={filterDropdownRef}>
                  <button
                    className="dropdown-btn"
                    onClick={() => setIsOpen(!isOpen)}
                    ref={filterButtonRef}
                    style={{
                      backgroundColor: "",
                      borderColor: "",
                      color: isOpen ? "#000000" : "#9a9b9b",
                    }}
                  >
                    {selectedOption}{" "}
                    <img
                      alt=""
                      className={`arrow ${isOpen && "rotate"}`}
                      src={dropdownAngle}
                    />
                  </button>
                  {isOpen && (
                    <ul className="dropdown-menu">
                      <li>
                        <button
                          onClick={() => {
                            handleOptionSelection("All subscribers");
                          }}
                        >
                          All subscribers
                          <img alt="Right Angle" src={rightAngle} />
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            handleOptionSelection("VIP members");
                          }}
                        >
                          VIP members <img alt="Right Angle" src={rightAngle} />
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            handleOptionSelection("VIP pending");
                          }}
                        >
                          VIP (pending){" "}
                          <img alt="Right Angle" src={rightAngle} />
                        </button>
                      </li>
                      {allTradingCapitals?.map((tradingCapital, index) => (
                        <li key={index}>
                          <button
                            onClick={() => {
                              handleOptionSelection(tradingCapital);
                            }}
                          >
                            Tier{" "}
                            {tradingCapital === "UNLIMITED" ||
                            tradingCapital === "VIP"
                              ? tradingCapital
                              : `$${tradingCapital}`}
                            <img alt="Right Angle" src={rightAngle} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="memberSearchBox">
              <SearchBar
                onChange={(e) => {
                  setEditEmailIndex(null);
                  setSelectedDropdownIndex(null);

                  ["VIP members", "VIP pending", "VIP"].includes(selectedOption)
                    ? setFilteredVipMembers(
                        vipMembers.filter((member) =>
                          member?.email
                            ?.toLowerCase()
                            ?.includes(e.target.value.toLowerCase())
                        )
                      )
                    : setFilteredParticipants(
                        participants.filter((participant) =>
                          participant?.user?.email
                            ?.toLowerCase()
                            ?.includes(e.target.value.toLowerCase())
                        )
                      );
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-12">
            <div className="fixedWidth1440 ms-auto">
              <div className="memberMessageBox mb-40px">
                <BubbleSvgButton
                  onClick={() => {
                    setIsSendMessages(true);
                    setSelectedUser(null);
                  }}
                  className={`${
                    isSendMessages
                      ? "active AllMemberMessageButton"
                      : "AllMemberMessageButton"
                  }`}
                  disabled={
                    participants.length === 0 && vipMembers.length === 0
                  }
                  text={"Message members"}
                  type="button"
                  variant="lightGray"
                  svgIcon={
                    <svg
                      height="12.941"
                      viewBox="1279.48 702.373 20.018 12.941"
                      width="20.018"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="noun-simple-message-icon-2920494">
                        <g data-name="Group 16342">
                          <path
                            d="M1298.013 702.373h-17.063c-.799 0-1.47.655-1.47 1.47v10c0 .8.655 1.47 1.47 1.47h17.079c.799 0 1.47-.654 1.47-1.47v-10a1.49 1.49 0 0 0-1.486-1.47Zm.112 11.47a.11.11 0 0 1-.112.113h-17.063a.11.11 0 0 1-.112-.112v-10.001a.11.11 0 0 1 .112-.112h17.079a.11.11 0 0 1 .112.112v10Z"
                            data-name="Path 10849"
                            fill-rule="evenodd"
                            fill={`${isSendMessages ? "white" : "#9a9b9b"}`}
                          />
                          <path
                            d="M1281.813 704.658v.926l7.38 4.186.289.16 7.668-4.346v-.926l-7.668 3.914-7.669-3.914Z"
                            data-name="Path 10850"
                            fill-rule="evenodd"
                            fill={`${isSendMessages ? "white" : "#9a9b9b"}`}
                          />
                        </g>
                      </g>
                    </svg>
                  }
                  svgPosition="right"
                  justifyContent="center"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table className="membersTable">
              <thead>
                <tr>
                  {["VIP members", "VIP pending", "VIP"].includes(
                    selectedOption
                  ) ? (
                    <>
                      <th className="email-th" style={{ width: "40%" }}>
                        Email
                      </th>
                      <th className="exp-th" style={{ width: "15%" }}>
                        Expiry Date
                      </th>
                      <th className="code-th" style={{ width: "15%" }}>
                        VIP code
                      </th>
                      <th className="status-th" style={{ width: "15%" }}>
                        Status
                      </th>
                      <th className="action-th" style={{ width: "15%" }}></th>
                    </>
                  ) : (
                    <>
                      <th className="email-th" style={{ width: "40%" }}>
                        Email
                      </th>
                      <th className="exp-th" style={{ width: "15%" }}>
                        Portfolio
                      </th>
                      <th className="code-th" style={{ width: "15%" }}>
                        Joined
                      </th>
                      <th className="status-th" style={{ width: "15%" }}>
                        Tier
                      </th>
                      <th className="action-th" style={{ width: "15%" }}></th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {["VIP members", "VIP pending", "VIP"].includes(
                  selectedOption
                ) ? (
                  filteredVipMembers.length > 0 ? (
                    filteredVipMembers
                      .slice((page - 1) * 10, page * 10)
                      .map((member, index) => (
                        <tr key={index}>
                          <td className="emailTD">
                            <div className="forDesktop">
                              {editEmailIndex === index ? (
                                <>
                                  <Form onSubmit={(e) => e.preventDefault()}>
                                    <Form.Group className="mb-2 mb-lg-0">
                                      <Form.Control
                                        autoFocus
                                        onChange={(e) =>
                                          setNewEmail(e.target.value)
                                        }
                                        placeholder="VIP member email"
                                        type="email"
                                        value={newEmail}
                                      />
                                    </Form.Group>
                                  </Form>
                                </>
                              ) : (
                                member?.email
                              )}
                            </div>
                            <div className="forMobile">
                              <div className="editEmailBox">
                                <p className="title">Email</p>
                                {editEmailIndex === index ? (
                                  <div className="mb-3">
                                    <Form onSubmit={(e) => e.preventDefault()}>
                                      <Form.Group className="mb-2 mb-lg-0">
                                        <Form.Control
                                          autoFocus
                                          onChange={(e) =>
                                            setNewEmail(e.target.value)
                                          }
                                          placeholder="VIP member email"
                                          type="email"
                                          value={newEmail}
                                        />
                                      </Form.Group>
                                      <div className="mt-2 d-flex d-lg-none d-md-none">
                                        <button
                                          className="UpdateButton"
                                          onClick={() => {
                                            setEditEmailIndex(null);
                                            updateVipMember(member._id, {
                                              email: newEmail,
                                            });
                                          }}
                                          size="sm"
                                          type="button"
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="ms-2 CancelButton"
                                          onClick={() =>
                                            setEditEmailIndex(null)
                                          }
                                          size="sm"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </Form>
                                  </div>
                                ) : (
                                  <p className="des">{member?.email}</p>
                                )}
                              </div>
                              <div className="combieneBox mt-2">
                                <div className="item">
                                  <p className="title">Expiry Date</p>
                                  <p className="des">
                                    {editExpiryDateIndex === index
                                      ? showExpiryDate(
                                          member?._id,
                                          member?.codeValidity
                                        )
                                      : member?.codeValidity === "Lifetime"
                                      ? member?.codeValidity
                                      : getEndDate(member?.endDate)}
                                  </p>
                                </div>
                                <div className="item">
                                  <p className="title">VIP code</p>
                                  <p className="des">
                                    {member?.vipCode}
                                    <button
                                      className="copyBtn"
                                      onClick={() => {
                                        navigator.clipboard
                                          .writeText(member?.vipCode)
                                          .then(() => {
                                            toastify(
                                              "Vip code copied to clipboard!",
                                              "success"
                                            );
                                          });
                                      }}
                                      type="button"
                                    >
                                      <img alt="" src={NewCopyIcon} />
                                    </button>
                                  </p>
                                </div>
                                <div className="item">
                                  <p className="title">Status</p>
                                  <p className="des">
                                    {member?.codeValidity ===
                                      "Specific end date" &&
                                    new Date(member?.endDate) < new Date() ? (
                                      <img alt="Expired" src={cancel} />
                                    ) : member?.isUsed ? (
                                      <img alt="Used" src={vipCheckMark} />
                                    ) : (
                                      "Pending"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="exp-td">
                            {editExpiryDateIndex === index
                              ? showExpiryDate(
                                  member?._id,
                                  member?.codeValidity
                                )
                              : member?.codeValidity === "Lifetime"
                              ? member?.codeValidity
                              : getEndDate(member?.endDate)}
                          </td>
                          <td className="code-td">
                            <div className="d-flex">
                              {member?.vipCode}
                              <button
                                className="copyBtn"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(member?.vipCode)
                                    .then(() => {
                                      toastify(
                                        "Vip code copied to clipboard!",
                                        "success"
                                      );
                                    });
                                }}
                                type="button"
                              >
                                <img alt="" src={NewCopyIcon} />
                              </button>
                            </div>
                          </td>
                          <td className="status-td">
                            <div className="statusInnerBox">
                              {editEmailIndex === index ? (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <button
                                    className="desktopSave btn d-none d-lg-block d-md-block"
                                    onClick={() => {
                                      setEditEmailIndex(null);
                                      updateVipMember(member._id, {
                                        email: newEmail,
                                      });
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : member?.codeValidity ===
                                  "Specific end date" &&
                                new Date(member?.endDate) < new Date() ? (
                                <img alt="Expired" src={cancel} />
                              ) : member?.isUsed ? (
                                <img alt="Used" src={vipCheckMark} />
                              ) : (
                                "Pending"
                              )}
                            </div>
                          </td>
                          <td
                            className="actionDropdown text-end"
                            ref={actionDropdownRef}
                          >
                            <div className="actionInnerBox">
                              {editEmailIndex === index ? (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="desktopCancle ms-2 d-none d-lg-block d-md-block"
                                    onClick={() => {
                                      setEditEmailIndex(null);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className=""
                                  onClick={() => {
                                    setEditExpiryDateIndex(null);
                                    setSelectedDropdownIndex(
                                      selectedDropdownIndex === index
                                        ? null
                                        : index
                                    );
                                  }}
                                  ref={actionButtonRef}
                                >
                                  <img alt="" src={tierAction} />
                                </button>
                              )}
                              {selectedDropdownIndex === index && (
                                <div className="dropdown-menu">
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setEditEmailIndex(index);
                                      setSelectedDropdownIndex(null);
                                    }}
                                  >
                                    Edit email
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setEditExpiryDateIndex(index);
                                      setSelectedDropdownIndex(null);
                                    }}
                                  >
                                    Change date
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setSelectedDropdownIndex(null);
                                    }}
                                  >
                                    Send code again
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsSendMessages(true);
                                      setSelectedDropdownIndex(null);
                                      setSelectedUser(member);
                                    }}
                                  >
                                    Send message
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setSelectedDropdownIndex(null);
                                      setShowDeleteModel({
                                        apiObj: {
                                          memberId: member?._id,
                                        },
                                        show: true,
                                      });
                                    }}
                                  >
                                    Delete member
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="5">
                        No member Found
                      </td>
                    </tr>
                  )
                ) : filteredParticipants.length > 0 ? (
                  filteredParticipants
                    .slice((page - 1) * 10, page * 10)
                    .map((participant, index) => (
                      <tr key={index}>
                        <td className="emailTD">
                          <div className="forDesktop">
                            {editEmailIndex === index ? (
                              <>
                                <Form onSubmit={(e) => e.preventDefault()}>
                                  <Form.Group className="mb-2 mb-lg-0">
                                    <Form.Control
                                      autoFocus
                                      onChange={(e) =>
                                        setNewEmail(e.target.value)
                                      }
                                      placeholder="VIP member email"
                                      type="email"
                                      value={newEmail}
                                    />
                                  </Form.Group>
                                </Form>
                              </>
                            ) : (
                              participant?.user?.email
                            )}
                          </div>
                          <div className="forMobile">
                            <div className="editEmailBox">
                              <p className="title">Email</p>
                              {editEmailIndex === index ? (
                                <div className="mb-3">
                                  <Form onSubmit={(e) => e.preventDefault()}>
                                    <Form.Group className="mb-2 mb-lg-0">
                                      <Form.Control
                                        autoFocus
                                        onChange={(e) =>
                                          setNewEmail(e.target.value)
                                        }
                                        placeholder="VIP member email"
                                        type="email"
                                        value={newEmail}
                                      />
                                    </Form.Group>
                                    <div className="mt-2 d-flex d-lg-none d-md-none">
                                      <button
                                        className="UpdateButton"
                                        onClick={() => {
                                          setEditEmailIndex(null);
                                        }}
                                        size="sm"
                                        type="button"
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="ms-2 CancelButton"
                                        onClick={() => setEditEmailIndex(null)}
                                        size="sm"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </Form>
                                </div>
                              ) : (
                                <p className="des">
                                  {participant?.user?.email}
                                </p>
                              )}
                            </div>
                            <div className="combieneBox mt-2">
                              <div className="item">
                                <p className="title">Portfolio</p>
                                <p className="des">
                                  $
                                  {simplifyTradingVolume(
                                    (participant?.binanceBalance?.balance ||
                                      0) +
                                      (participant?.binanceBalance
                                        ?.coinFutureBalance || 0) +
                                      (participant?.bybitBalance?.balance || 0)
                                  )}
                                </p>
                              </div>
                              <div className="item">
                                <p className="title">Joined</p>
                                <p className="des">
                                  {(() => {
                                    const date = new Date(participant.date);
                                    return `${
                                      date.getMonth() + 1
                                    }.${date.getDate()}.${date.getFullYear()}`;
                                  })()}
                                </p>
                              </div>
                              <div className="item">
                                <p className="title">Tier</p>
                                <p className="des">
                                  {getTradingCapital(participant)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="exp-td">
                          $
                          {simplifyTradingVolume(
                            (participant?.binanceBalance?.balance || 0) +
                              (participant?.binanceBalance?.coinFutureBalance ||
                                0) +
                              (participant?.bybitBalance?.balance || 0)
                          )}
                        </td>
                        <td className="code-td">
                          {(() => {
                            const date = new Date(participant.date);
                            return `${
                              date.getMonth() + 1
                            }.${date.getDate()}.${date.getFullYear()}`;
                          })()}
                        </td>
                        <td className="status-td">
                          <div className="statusInnerBox">
                            {editEmailIndex === index ? (
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <button
                                  className="desktopSave btn d-none d-lg-block d-md-block"
                                  onClick={() => {
                                    setEditEmailIndex(null);
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            ) : (
                              getTradingCapital(participant)
                            )}
                          </div>
                        </td>
                        <td
                          className="actionDropdown text-end"
                          ref={actionDropdownRef}
                        >
                          <div className="actionInnerBox">
                            {editEmailIndex === index ? (
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  className="desktopCancle ms-2 d-none d-lg-block d-md-block"
                                  onClick={() => {
                                    setEditEmailIndex(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                className=""
                                onClick={() => {
                                  setSelectedDropdownIndex(
                                    selectedDropdownIndex === index
                                      ? null
                                      : index
                                  );
                                }}
                                ref={actionButtonRef}
                              >
                                <img alt="" src={tierAction} />
                              </button>
                            )}
                            {selectedDropdownIndex === index && (
                              <div className="dropdown-menu">
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    setEditEmailIndex(index);
                                    setSelectedDropdownIndex(null);
                                  }}
                                >
                                  Edit email
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    setIsSendMessages(true);
                                    setSelectedDropdownIndex(null);
                                    setSelectedUser(participant.user);
                                  }}
                                >
                                  Send message
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    const _foundCommunity =
                                      participant.user.memberCommunities?.find(
                                        (community) =>
                                          community.community ===
                                          communityLeaderId
                                      );

                                    setSelectedDropdownIndex(null);
                                    setSelectedParticipant(
                                      participant.user._id
                                    );
                                    setSelectedSubscription(
                                      _foundCommunity?.sessionId
                                    );
                                    setShowDeleteModel({
                                      apiObj: {},
                                      show: true,
                                    });
                                  }}
                                >
                                  Delete member
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      No member Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-end ms-auto mt-41">
            <CustomPagination
              filtered={
                ["VIP members", "VIP pending", "VIP"].includes(selectedOption)
                  ? filteredVipMembers
                  : filteredParticipants
              }
              items={
                ["VIP members", "VIP pending", "VIP"].includes(selectedOption)
                  ? filteredVipMembers
                  : filteredParticipants
              }
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
        <div className="deleteModal">
          <Modal
            backdrop="false"
            id="deleteModal"
            onHide={() => {
              setShowDeleteModel({ apiObj: {}, show: false });
            }}
            show={showDeleteModel.show}
          >
            <Modal.Body>
              <div className="modalContent">
                <Button
                  className="closeBtn"
                  onClick={() => {
                    setShowDeleteModel({ apiObj: {}, show: false });
                  }}
                >
                  <img alt="Close Modal" src={cross} />
                </Button>
                <div className="regBox">
                  <div className="img-box">
                    <img alt="Success Bell" src={vipBellPng} />
                  </div>
                  <div className="content-box">
                    <p>
                      <span>Do you want to delete member?</span>
                      <br />
                      Member will be deleted. You can add member any time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="buttonDiv d-flex algn-items-center justify-content-center w-100">
                <button
                  className="DeleteMemberButton"
                  onClick={() => {
                    ["VIP members", "VIP pending", "VIP"].includes(
                      selectedOption
                    )
                      ? updateVipMember(showDeleteModel.apiObj?.memberId, {
                          isDelete: true,
                        })
                      : unsubscribeMember();
                    setShowDeleteModel({ show: false, apiObj: {} });
                  }}
                  type="button"
                >
                  Yes Delete
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>

      {isSendMessages && (
        <MessageModal
          handleClose={() => {
            setIsSendMessages(false);
          }}
          selectedUser={selectedUser}
          show={isSendMessages}
        />
      )}
    </>
  );
};

export default AllMembers;

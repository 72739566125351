// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import Cross from "../../../../assets/images/green-corss.svg";
// import "./InfoPopup.css";
// import InfoIcon from "../../../../assets/images/teir-info-icon.png";

// const InfoPopup = ({ contentItems = [] }) => {
//     const [show, setShow] = useState(false);

//     const handleToggle = () => {
//         setShow((prev) => !prev);
//     };

//     const renderContent = () => {
//         return contentItems.map((item, index) => {
//             if (item.type === "heading") {
//                 return (
//                     <div key={`heading-${index}`} className="infoPopupHeading">
//                         <p>
//                             <span className="mb-1 d-block bold">{item.text}</span>
//                         </p>
//                     </div>
//                 );
//             }

//             if (item.type === "paragraph") {
//                 return (
//                     <p key={`content-${index}`} dangerouslySetInnerHTML={{ __html: item.text }} />
//                 );
//             }

//             return null;
//         });
//     };

//     return (
//         <div className="infoPopupContainer">
//             <button type="button" className="infoButton" onClick={handleToggle}>
//                 <img src={InfoIcon} alt="infoIcon" />
//             </button>
//             {show && (
//                 <div className="infoPopup">
//                     <button
//                         type="button"
//                         className="closePopupButton"
//                         onClick={handleToggle}
//                     >
//                         <img src={Cross} alt="Close" />
//                     </button>
//                     {renderContent()}
//                 </div>
//             )}
//         </div>
//     );
// };

// InfoPopup.propTypes = {
//     contentItems: PropTypes.arrayOf(
//         PropTypes.shape({
//             type: PropTypes.oneOf(["heading", "paragraph"]).isRequired,
//             text: PropTypes.string.isRequired,
//         })
//     ),
// };

// export default InfoPopup;



import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Cross from "../../../../assets/images/green-corss.svg";
import "./InfoPopup.css";
import InfoIcon from "../../../../assets/images/teir-info-icon.png";

const InfoPopup = ({ contentItems = [] }) => {
    const [show, setShow] = useState(false);
    const popupRef = useRef(null);

    const handleToggle = () => {
        setShow((prev) => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShow(false);
            }
        };

        // Add event listener when popup is shown
        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Cleanup event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]); // Re-run effect when show changes

    const renderContent = () => {
        return contentItems.map((item, index) => {
            if (item.type === "heading") {
                return (
                    <div key={`heading-${index}`} className="infoPopupHeading">
                        <p>
                            <span className="mb-1 d-block bold">{item.text}</span>
                        </p>
                    </div>
                );
            }

            if (item.type === "paragraph") {
                return (
                    <p key={`content-${index}`} dangerouslySetInnerHTML={{ __html: item.text }} />
                );
            }

            return null;
        });
    };

    return (
        <div className="infoPopupContainer">
            <button type="button" className="infoButton" onClick={handleToggle}>
                <img src={InfoIcon} alt="infoIcon" />
            </button>
            {show && (
                <div className="infoPopup" ref={popupRef}>
                    <button
                        type="button"
                        className="closePopupButton"
                        onClick={handleToggle}
                    >
                        <img src={Cross} alt="Close" />
                    </button>
                    {renderContent()}
                </div>
            )}
        </div>
    );
};

InfoPopup.propTypes = {
    contentItems: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["heading", "paragraph"]).isRequired,
            text: PropTypes.string.isRequired,
        })
    ),
};

export default InfoPopup;
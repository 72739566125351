import "./SubscriptionCard.css";
import { toastify } from "../../../../helpers/toast/toastify";
import { updateUser } from "../../../../redux/users/usersSlice";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Arrow from "../../../../assets/images/rightAnglePath.svg";
import DeleteMember from "../../../../components/modals/DeleteMember";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import TierBoxes from "./TierBoxes/TierBoxes";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import BubbleSvgButton from "../../CommonComponents/BubbleSvgButton/BubbleSvgButton";

const options = [
  {
    label: "No trial",
    value: "0",
  },
  {
    label: "7 days ",
    value: "7",
  },
  {
    label: "14 days",
    value: "14",
  },
  {
    label: "1 Month",
    value: "30",
  },
];

const SubscriptionCard = () => {
  const [editMode, setEditMode] = useState(false);
  const [editOldData, setEditOldData] = useState({});
  const [showModal, setShowModal] = useState({ apiObject: {}, show: false });
  const [tiers, setTiers] = useState([]);

  const user = useSelector((value) => value?.user?.user);
  const api = useApi();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    monthlyfee: "",
    tradingCapital: "",
    trialPeriodDays: "",
    trialPeriodLabel: "Select Trial",
    yearlyfee: "",
  });

  const [formErrors, setFormErrors] = useState({
    monthlyfee: false,
    tradingCapital: false,
    yearlyfee: false,
  });

  const [visibleDropdowns, setVisibleDropdowns] = useState({});
  const [selectTrialDropdown, setSelectTrialDropdown] = useState(false);

  const handleDropdownToggle = (index) => {
    setVisibleDropdowns((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleOptionClick = (option, index, tier) => {
    if (option === "Edit") {
      setEditMode(true);
      const trialPeriodOption = options.find(
        (option) => option.value === tier?.trialPeriodDays
      );

      const oldSubscription = {
        monthlyfee: tier?.monthlyfee,
        yearlyfee: tier?.yearlyfee,
        tradingCapital: tier?.tradingCapital,
        trialPeriodDays: tier?.trialPeriodDays,
        trialPeriodLabel: trialPeriodOption ? trialPeriodOption.label : "0",
      };
      setEditOldData({ ...oldSubscription, oldSubscriptionId: tier?._id });

      setFormData(oldSubscription);
    } else if (option === "Delete") {
      setShowModal({ show: true, apiObject: tier });
    }

    setVisibleDropdowns((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleInputChange = (event, field) => {
    const value = event.target.value;

    const patterns = {
      monthlyfee: /^[0-9]*\.?[0-9]+$/,
      tradingCapital: /^[A-Za-z0-9]*$/,
      yearlyfee: /^[0-9]*\.?[0-9]+$/,
    };

    const pattern = patterns[field];

    if (pattern) {
      if (value === "" || pattern.test(value))
        setFormData({
          ...formData,
          [field]: value,
        });
    } else
      setFormData({
        ...formData,
        [field]: value,
      });
  };

  const handleTier = async () => {
    if (checkFormValidation()) {
      if (editMode) {
        if (
          editOldData?.monthlyfee === formData?.monthlyfee &&
          editOldData?.tradingCapital === formData?.tradingCapital &&
          editOldData?.trialPeriodDays === formData?.trialPeriodDays &&
          editOldData?.yearlyfee === formData?.yearlyfee
        ) {
          toastify("Please Edit some thing to update your subscription");
        } else {
          const response = await api("post", "copyTrade/editSubscription", {
            subscription: formData,
            oldSubscriptionId: editOldData?.oldSubscriptionId,
          });
          if (response?.status) {
            fetchUserData();
            setEditMode(false);
            setFormData({
              monthlyfee: "",
              yearlyfee: "",
              tradingCapital: "",
              trialPeriodDays: "",
              trialPeriodLabel: "Select Trial",
            });

            setFormErrors({
              monthlyfee: false,
              yearlyfee: false,
              tradingCapital: false,
            });

            toastify("Subscription updated successfully", "success");
          }
        }
      } else {
        const response = await api("post", "copyTrade/submitSubscription", {
          subscription: formData,
        });

        if (response?.status) {
          fetchUserData();

          setFormData({
            monthlyfee: "",
            yearlyfee: "",
            tradingCapital: "",
            trialPeriodDays: "",
            trialPeriodLabel: "Select Trial",
          });

          setFormErrors({
            monthlyfee: false,
            yearlyfee: false,
            tradingCapital: false,
          });

          toastify("Subscription added successfully", "success");
        }
      }
    }
  };

  const fetchUserData = async () => {
    const myResponse = await api("get", "users/index");
    if (myResponse) {
      dispatch(updateUser(myResponse?.data?.user));
    }
  };

  useEffect(() => {
    const allSubscriptions = user?.leaderCommunity?.subscription;
    if (!allSubscriptions) return;

    const activeSubscription = allSubscriptions.filter((tier) => tier.isActive);

    setFormData((prevState) => ({
      ...prevState,
      trialPeriodDays: activeSubscription.at(-1)?.trialPeriodDays || "",
      trialPeriodLabel:
        options.find(
          (option) =>
            option.value === activeSubscription.at(-1)?.trialPeriodDays
        )?.label || "",
    }));

    setTiers(activeSubscription);
  }, [user]);

  const checkFormValidation = () => {
    const maxCapital = formData?.tradingCapital?.trim() || "";
    const monthlyfee = formData?.monthlyfee?.trim() || "";
    const trialPeriodDays = formData?.trialPeriodDays || "";
    const yearlyfee = formData?.yearlyfee?.trim() || "";

    const errors = {};
    let formValid = true;

    if (!monthlyfee) {
      toastify("Please enter a monthly price");
      errors.monthlyfee = true;
      formValid = false;
    } else {
      const monthlyNum = parseFloat(monthlyfee);
      if (isNaN(monthlyNum) || monthlyNum <= 0 || monthlyNum >= 10000) {
        toastify(
          "Please enter a valid positive number (1-9999) for monthly price"
        );
        errors.monthlyfee = true;
        formValid = false;
      } else {
        errors.monthlyfee = false;
      }
    }

    if (!yearlyfee) {
      toastify("Please enter a yearly price");
      errors.yearlyfee = true;
      formValid = false;
    } else {
      const yearlyNum = parseFloat(yearlyfee);
      if (isNaN(yearlyNum) || yearlyNum <= 0 || yearlyNum >= 10000) {
        toastify(
          "Please enter a valid positive number (1-9999) for yearly price"
        );
        errors.yearlyfee = true;
        formValid = false;
      } else {
        errors.yearlyfee = false;
      }
    }

    if (!maxCapital) {
      toastify("Please enter a trading capital");
      errors.tradingCapital = true;
      formValid = false;
    } else if (maxCapital.toUpperCase() === "UNLIMITED") {
      errors.tradingCapital = false;
    } else {
      const capitalNum = parseFloat(maxCapital);
      if (isNaN(capitalNum) || capitalNum <= 0 || capitalNum >= 1000000) {
        toastify(
          "Please enter a valid positive number (1-999999) or 'UNLIMITED' for trading capital"
        );
        errors.tradingCapital = true;
        formValid = false;
      } else {
        errors.tradingCapital = false;
      }
    }

    if (!trialPeriodDays) {
      toastify("Please select a free trial period");
      formValid = false;
    }

    setFormErrors((prev) => ({
      ...prev,
      ...errors,
    }));

    return formValid;
  };

  const deleteSubscription = async () => {
    const response = await api("post", "copyTrade/deleteSubscription", {
      subscriptionId: showModal?.apiObject?._id,
    });

    setShowModal({ apiObject: {}, show: false });

    if (response?.status) {
      fetchUserData();

      toastify("Subscription deleted successfully", "success");
    }
  };

  const selectTierRef = useRef(null);
  const tierRef = useRef(null);

  const handleClickOutsideDropdown = (e) => {
    if (selectTierRef.current && !selectTierRef.current.contains(e.target))
      setSelectTrialDropdown(false);

    if (tierRef.current && !tierRef.current.contains(e.target))
      setVisibleDropdowns({});
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <div className="subscriptionBox">
        <div className="row">
          <div className="col-lg-6 col-md-7 col-12">
            <p className="title">Subscription</p>
            <div className="subTitle">
              Define your subscription plan(s)
              <InfoPopup
                contentItems={[
                  { text: "Subscription Tier", type: "heading" },
                  {
                    text: " Min. 1 subscription tier is needed for your copy trading to work. We'll add $15 to your subscription fee. Maximum trading capital is the amount of capital subscribers  will be copy trading with. This feature makes it possible for you to charge more for subscribers with more capital. Leave it blank for unlimited trading capital.",
                    type: "paragraph",
                  },
                ]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-5 col-12"></div>
        </div>

        <div className="row">
          {/* Monthly price input */}
          <div className="col-lg-3 col-md-6 col-12">
            <div className="mb-30px">
              <FloatingTextFiled
                className={`fixedWidth1440 ${
                  formErrors.monthlyfee && "invalid"
                }`}
                forLabel="monthly-price"
                id="monthly-price"
                label="Monthly price ($)"
                onChange={(e) => handleInputChange(e, "monthlyfee")}
                placeholder="Monthly price ($)"
                value={formData.monthlyfee}
              />
            </div>
          </div>

          {/* Yearly price input */}
          <div className="col-lg-3 col-md-6 col-12">
            <div className="mb-30px">
              <FloatingTextFiled
                className={`fixedWidth1440 mx-auto ${
                  formErrors.yearlyfee && "invalid"
                }`}
                forLabel="yearly-price"
                id="yearly-price"
                label="Yearly price ($)"
                onChange={(e) => handleInputChange(e, "yearlyfee")}
                placeholder="Yearly price ($)"
                value={formData.yearlyfee}
              />
            </div>
          </div>

          {/* Minimum trading capital input */}
          <div className="col-lg-3 col-md-6 col-12">
            <div className="mb-30px">
              <FloatingTextFiled
                className={`fixedWidth1440 mx-auto ${
                  formErrors.tradingCapital && "invalid"
                }`}
                forLabel="maximum-trading-capital"
                id="maximum-trading-capital"
                label="Max. Trading Capital"
                onChange={(e) => handleInputChange(e, "tradingCapital")}
                placeholder="Max. Trading Capital"
                value={formData.tradingCapital}
              />
            </div>
          </div>

          {/* Maximum trading capital input */}
          {(tiers?.length < 5 || editMode) && (
            <div className="col-lg-3 col-md-6 col-12">
              <div className="mb-30px fixedWidth1440 ms-auto">
                <BubbleSvgButton
                  onClick={handleTier}
                  text={editMode ? "Update tier" : "Add tier"}
                  type="button"
                  variant="green"
                  justifyContent="center"
                />
              </div>
            </div>
          )}
        </div>

        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <p className="inputBottomText">
              Members are billed{" "}
              <span className="BoldText">
                ${formData.monthlyfee ? +formData.monthlyfee + 15 : 0}
              </span>{" "}
              monthly /{" "}
              <span className="BoldText">
                ${formData.yearlyfee ? +formData.yearlyfee + 180 : 0}
              </span>{" "}
              yearly
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div
              className="select-trial-dropdown-container"
              ref={selectTierRef}
            >
              <div className="select-trial-dropdown-container-inner">
                <button
                  className="select-trial-button"
                  onClick={() => {
                    setSelectTrialDropdown((prevState) => !prevState);
                  }}
                >
                  <img
                    alt=""
                    className="dropdown-arrow"
                    src={dropdownAngle}
                    style={{
                      transform: selectTrialDropdown
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                  <span>{formData.trialPeriodLabel}</span>
                </button>
                <span className="infoSpan">
                  <InfoPopup
                    contentItems={[
                      { text: "Free trial", type: "heading" },
                      {
                        text: "We recommend you offer some kind of free trial. The subscribers won't be charged if subscription is cancelled before end of trial.",
                        type: "paragraph",
                      },
                    ]}
                  />
                </span>
              </div>

              {selectTrialDropdown && (
                <div className="custom-dropdown">
                  <ul>
                    {options.map((option) => (
                      <li key={option.value}>
                        <button
                          onClick={() => {
                            setFormData({
                              ...formData,
                              trialPeriodDays: option.value,
                              trialPeriodLabel: option.label,
                            });
                            setSelectTrialDropdown(false);
                          }}
                        >
                          {option.label}
                          <img src={Arrow} alt="Arrow Icon" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <TierBoxes
        handleDropdownToggle={handleDropdownToggle}
        handleOptionClick={handleOptionClick}
        tierRef={tierRef}
        tiers={tiers}
        visibleDropdowns={visibleDropdowns}
      />

      {showModal?.show && (
        <DeleteMember
          noAction={() => {
            setShowModal({ apiObject: {}, show: false });
          }}
          subtext="Are you sure you want to delete this pricing?"
          text="Delete subscription"
          yesAction={deleteSubscription}
        />
      )}
    </>
  );
};

export default SubscriptionCard;

import "./AccountSecurity.css";
import { storeUser } from "../../../../redux/users/usersSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { useDispatch, useSelector } from "react-redux";
import FloatingDropdown from "../../CommonComponents/FloatingDropdown/FloatingDropdown";
import React, { useEffect, useState } from "react";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import FloatingDropdownWithArrow from "../../CommonComponents/FloatingDropdownWithArrow/FloatingDropdownWithArrow";

const AccountSecurity = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);

  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [security, setSecurity] = useState({});
  const [selectedOptionForLogout, setSelectedOptionForLogout] = useState("");
  const [selectedOptionForOtp, setSelectedOptionForOtp] = useState("");

  const options = ["Yes", "No"];

  const save = async () => {
    const logout = selectedOptionForLogout === "Yes" ? true : false;
    const otp = selectedOptionForOtp === "Yes" ? true : false;

    let payload = {
      security: {
        ...security,
        logout,
        otp,
      },
    };

    const response = await api("post", "users/updateAccountSettings", {
      ...payload,
    });
    if (response?.status) {
      toastify("Account Information updated successfully.", "success");

      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toastify(response?.message);
    }
  };

  useEffect(() => {
    setSecurity(user?.security);
    setSelectedOptionForLogout(user?.security?.logout ? "Yes" : "No");
    setSelectedOptionForOtp(user?.security?.otp ? "Yes" : "No");
  }, [user]);

  const logout = selectedOptionForLogout === "Yes" ? true : false;
  const otp = selectedOptionForOtp === "Yes" ? true : false;

  useEffect(() => {
    if (security.logout !== logout || security.otp !== otp) setIsChanged(true);
    else setIsChanged(false);
  }, [security, selectedOptionForLogout, selectedOptionForOtp]);

  return (
    <>
      <div className="accountSecrityBox">
        <div className="headingBox">
          <h2 className="title">Account Security</h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12">
            <div
              className={`fixedWidth1440  ${
                security.otp === otp ? "activeFadeText" : ""
              }`}
            >
              <FloatingDropdownWithArrow
                id="otp"
                label="One time password on login"
                options={options}
                selectedValue={selectedOptionForOtp}
                setSelectedValue={setSelectedOptionForOtp}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div
              className={`fixedWidth1440 mx-auto ${
                security.logout === logout ? "activeFadeText" : ""
              }`}
            >
              <FloatingDropdownWithArrow
                id="inactivity"
                label="Log out after 15 minutes inactivity?"
                options={options}
                selectedValue={selectedOptionForLogout}
                setSelectedValue={setSelectedOptionForLogout}
              />
            </div>
          </div>
          {isChanged && (
            <div className="col-lg-3 offset-lg-3 col-md-4 col-12">
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    save();
                  }}
                  className="submitbtn"
                >
                  Save edits
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountSecurity;

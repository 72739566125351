import "./FloatingTextFiled.css";
import React from "react";

const FloatingTextFiled = ({
  className = "",
  disabled = false,
  forLabel,
  id,
  label,
  onBlur,
  onChange,
  onClick,
  placeholder,
  type = "text",
  value,
}) => {
  return (
    <>
      <div className={`CopyItCustomInput ${className}`}>
        <div className="form-floating">
          <input
            className={`form-control fs15`}
            disabled={disabled}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
          />
          <label htmlFor={forLabel}>{label}</label>
        </div>
      </div>
    </>
  );
};

export default FloatingTextFiled;

import React from "react";
import Reports from "../sharedPages/performance/Reports";

const BusinessReport = () => {
  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="">
          <div className="mx-691 mx-auto">
            <Reports />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessReport;

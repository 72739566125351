import PayoutHistory from "../sharedPages/performance/PayoutHistory";
import React from "react";

const History = () => {
  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="">
          <div className="mx-691 mx-auto">
            <PayoutHistory />
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;

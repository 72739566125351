import AccountDetail from "./AccountDetail/AccountDetail";
import AccountSecurity from "./AccountSecurity/AccountSecurity";
import React from "react";

const NewSetting = () => {
  return (
    <>
      <div className="AccountSettingPage">
        <h2 class="PageTitle text_GB fs20 mt-smLayout-30">Settings</h2>
        <AccountDetail />
        <AccountSecurity />
      </div>
    </>
  );
};

export default NewSetting;

import "./MemberListing.css";
import AddVipMembers from "./AddVipMembers/AddVipMembers";
import AllMembers from "./AllMembers/AllMembers";
import MemberStats from "./MemberStats/MemberStats";
import React, { useState } from "react";

const MemberListening = () => {
  const [isNewVipAdded, setIsNewVipAdded] = useState(false);

  return (
    <>
      <div className="MemberLisitingPage">
        <div className="container-fluid g-0">
          <h2 className="PageTitle text_GB fs20 mt-smLayout-30">Members</h2>
          <MemberStats />
          <AddVipMembers setIsNewVipAdded={setIsNewVipAdded} />
          <AllMembers isNewVipAdded={isNewVipAdded} />
        </div>
      </div>
    </>
  );
};

export default MemberListening;

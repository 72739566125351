import "./TierBoxes.css";
import Action from "../../../../../assets/images/tierActionNew.svg";
import React from "react";

const TierBoxes = ({
  handleDropdownToggle,
  handleOptionClick,
  tierRef,
  tiers,
  visibleDropdowns,
}) => {
  return (
    <div className="tierBoxesDiv">
      <div className="tierBoxesDivRow">
        {tiers.map((tier, index) => (
          <div className="tierBoxShall" key={index}>
            <div className="tierBox">
              <p className="tierTitle">{`Tier ${index + 1}`}</p>
              <div className="d-flex align-items-center justify-content-between">
                <p className="tierPrice">{`$${tier.monthlyfee} /  $${tier.yearlyfee}`}</p>

                <div className="dropdown-container">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();

                      handleDropdownToggle(index);
                    }}
                  >
                    {/* <img src={Action} alt="Action Icon" /> */}
                    <svg
                      fill="#ffffff"
                      height="11.521"
                      viewBox="1046 439.218 16 12"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="Group 16037">
                        <g
                          clip-path='url("#a")'
                          data-name="Group 16036"
                          transform="translate(1046 439.218)"
                        >
                          <path
                            d="M6.31.001h4.916c.833.002 1.432.647 1.376 1.477-.045.662-.591 1.182-1.29 1.23a1.908 1.908 0 0 1-.13.003H1.435c-.65 0-1.158-.34-1.357-.904A1.35 1.35 0 0 1 1.351.003C3.004-.003 4.657.001 6.31.001"
                            data-name="Path 8814"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M6.309 11.52h-4.93C.672 11.517.09 11.015.015 10.352c-.096-.85.507-1.535 1.363-1.542.69-.005 1.379 0 2.068 0h7.75c.676 0 1.22.406 1.371 1.02a1.35 1.35 0 0 1-1.314 1.688c-1.596.004-3.19.001-4.786.001h-.16"
                            data-name="Path 8815"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M6.308 4.403c1.649 0 3.297-.005 4.945.003.553.003.974.264 1.216.762.237.486.188.967-.133 1.404-.272.37-.652.545-1.11.545-1.359.002-2.718 0-4.077 0-1.923 0-3.846.002-5.77 0-.717 0-1.294-.515-1.367-1.212a1.35 1.35 0 0 1 1.351-1.5c1.417-.004 2.834-.002 4.251-.002h.694"
                            data-name="Path 8816"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M13.29 1.357c0-.756.602-1.357 1.358-1.355.747.001 1.35.603 1.352 1.35a1.36 1.36 0 0 1-1.361 1.358 1.352 1.352 0 0 1-1.349-1.353"
                            data-name="Path 8817"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M13.29 5.76a1.354 1.354 0 1 1 2.71.008 1.362 1.362 0 0 1-1.353 1.35A1.355 1.355 0 0 1 13.29 5.76"
                            data-name="Path 8818"
                            fill-rule="evenodd"
                          />
                          <path
                            d="M14.644 11.52a1.347 1.347 0 0 1-1.354-1.36 1.351 1.351 0 0 1 1.352-1.35c.75 0 1.363.615 1.358 1.363a1.354 1.354 0 0 1-1.356 1.347"
                            data-name="Path 8819"
                            fill-rule="evenodd"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path
                            d="M0 0h16v11.521H0V0z"
                            data-name="Rectangle 129"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>

                  {visibleDropdowns[index] && (
                    <div className="custom-dropdown" ref={tierRef}>
                      <ul>
                        <li
                          onClick={(e) => {
                            handleOptionClick("Edit", index, tier);
                          }}
                        >
                          Edit
                        </li>
                        <li
                          onClick={(e) => {
                            handleOptionClick("Delete", index, tier);
                          }}
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TierBoxes;

import "./NewLayout.css";
import { leaderSideBarItems } from "../pages/sharedPages/NewSidebar/LeaderSideBar";
import { memberSideBarItems } from "../pages/sharedPages/NewSidebar/MemberSideBar";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { setCurrentTab } from "../redux/links/linksSlice";
import { setShowSidebar } from "../redux/sidebar/sidebarSlice";
import { storeUser } from "../redux/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import NewSidebar from "../pages/sharedPages/NewSidebar/NewSidebar";
import ProfileDropdown from "../pages/sharedPages/ProfileDropdown/ProfileDropdown";
import React, { useEffect, useRef, useState } from "react";
import SettingDropdown from "../pages/sharedPages/SettingDropdown/SettingDropdown";
import useApi from "../helpers/apiHelper/requestHelper";
import Pagination from "../pages/sharedPages/CommonComponents/NewPagination/NewPagination";

const NewLayout = () => {
  const [searchParams] = useSearchParams();
  const [userRole, setUserRole] = useState("");

  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((e) => e?.user?.user);

  const page = searchParams.get("page");
  const role = searchParams.get("role");
  const token = searchParams.get("token");

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem("token", token);

        const user = await fetchUserData();
        setUserRole(user?.user?.role);

        if (role === "leader" && page === "copyTrade")
          dispatch(setCurrentTab(user?.user?.onboardingStep || "subscription"));

        if (role === "member" && page === "copyTrade")
          dispatch(setCurrentTab(user?.user?.onboardingStep || "connection"));

        dispatch(setShowSidebar(page === "dashboard"));
        navigate(`/${role}/${page}`);
      } else {
        const user = await fetchUserData();
        setUserRole(user?.user?.role);
      }
    };

    fetchData();
  }, []);

  const fetchUserData = async () => {
    const response = await api("get", "users/index");

    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }

    return response?.data;
  };

  return (
    <div className="newLayoutBox">
      <div className={`${user?.firstVisit ? "d-none" : "sideBarBox"}`}>
        {/* <SideBar newLayout={true} /> */}
        <NewSidebar
          items={
            userRole === "leader" ? leaderSideBarItems : memberSideBarItems
          }
        />
      </div>
      <div className={`mainContentBox ${user?.firstVisit && "m-left-0"}`}>
        <div className={`container-fluid g-0 ${user?.firstVisit && "d-none"}`}>
          {/* <Pagination/>  */}
          <div className="settingProfileBox">
            <SettingDropdown />
            <ProfileDropdown />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default NewLayout;

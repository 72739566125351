import React, { useState } from "react";
import axios from "axios";

const DeveloperScripts = () => {
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [mongoPassword, setMongoPassword] = useState("");
  const [showMongoPassword, setShowMongoPassword] = useState(false);
  const [binanceApiKey, setBinanceApiKey] = useState("");
  const [binanceSecretKey, setBinanceSecretKey] = useState("");
  const [binanceResponse, setBinanceResponse] = useState("");
  const [showBinanceResponse, setShowBinanceResponse] = useState(false);
  const [bybitApiKey, setBybitApiKey] = useState("");
  const [bybitSecretKey, setBybitSecretKey] = useState("");
  const [bybitResponse, setBybitResponse] = useState("");
  const [showBybitResponse, setShowBybitResponse] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}developerScripts/seePasswordbyEmail`,
        { email }
      );

      const data = response?.data?.data;
      if (data) {
        setPassword(data);
        setShowPassword(true);
        setShowMongoPassword(false);
      }
    } catch (error) {
      console.error("Error fetching password:", error);
    }
  };

  const handleMongoIdSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}developerScripts/seePasswordbyId`,
        { id: mongoId }
      );

      const data = response?.data?.data;
      if (data) {
        setMongoPassword(data);
        setShowMongoPassword(true);
        setShowPassword(false);
      }
    } catch (error) {
      console.error("Error checking MongoDB ID:", error);
    }
  };

  const handleBinanceSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}developerScripts/seeBinanceApiKey`,
        {
          findApiKey: binanceApiKey,
          findSKey: binanceSecretKey,
        }
      );

      const data = response?.data?.data;
      if (data && data[0]) {
        setBinanceResponse(data[0]);
        setShowBinanceResponse(true);
      }
    } catch (error) {
      console.error("Error checking Binance keys:", error);
    }
  };

  const handleBybitSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}developerScripts/seeBybitApiKey`,
        {
          findApiKey: bybitApiKey,
          findSKey: bybitSecretKey,
        }
      );

      const data = response?.data?.data;
      if (data && data[0]) {
        setBybitResponse(data[0]);
        setShowBybitResponse(true);
      }
    } catch (error) {
      console.error("Error checking Bybit keys:", error);
    }
  };

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "16px" }}>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "28rem", margin: "0 auto" }}
      >
        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="email"
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            Write Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
            required
          />
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: "#2563eb",
            color: "white",
            padding: "8px 24px",
            borderRadius: "6px",
            fontWeight: "500",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            transition: "background-color 150ms ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
        >
          Show Password
        </button>
        {showPassword && (
          <div
            style={{
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#f3f4f6",
              borderRadius: "4px",
            }}
          >
            <input
              type="text"
              value={password}
              readOnly
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #e5e7eb",
                borderRadius: "4px",
                outline: "none",
                backgroundColor: "white",
              }}
            />
          </div>
        )}
      </form>

      <div style={{ maxWidth: "28rem", margin: "32px auto 0" }}>
        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="mongoId"
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            MongoDB ID
          </label>
          <input
            type="text"
            id="mongoId"
            value={mongoId}
            onChange={(e) => setMongoId(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
          />
        </div>
        <button
          onClick={handleMongoIdSubmit}
          style={{
            backgroundColor: "#2563eb",
            color: "white",
            padding: "8px 24px",
            borderRadius: "6px",
            fontWeight: "500",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            transition: "background-color 150ms ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
        >
          Check MongoDB ID
        </button>
        {showMongoPassword && (
          <div
            style={{
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#f3f4f6",
              borderRadius: "4px",
            }}
          >
            <input
              type="text"
              value={mongoPassword}
              readOnly
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #e5e7eb",
                borderRadius: "4px",
                outline: "none",
                backgroundColor: "white",
              }}
            />
          </div>
        )}
      </div>

      {/* Binance Form */}
      <div style={{ maxWidth: "28rem", margin: "32px auto 0" }}>
        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            Binance API Key
          </label>
          <input
            type="text"
            value={binanceApiKey}
            onChange={(e) => setBinanceApiKey(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            Binance Secret Key
          </label>
          <input
            type="text"
            value={binanceSecretKey}
            onChange={(e) => setBinanceSecretKey(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
          />
        </div>
        <button
          onClick={handleBinanceSubmit}
          style={{
            backgroundColor: "#2563eb",
            color: "white",
            padding: "8px 24px",
            borderRadius: "6px",
            fontWeight: "500",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            transition: "background-color 150ms ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
        >
          Check Binance Keys
        </button>
        {showBinanceResponse && (
          <div
            style={{
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#f3f4f6",
              borderRadius: "4px",
            }}
          >
            {binanceResponse && (
              <div style={{ display: "grid", gap: "8px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "120px 1fr",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>User Email:</span>
                  <input
                    type="text"
                    value={binanceResponse.email || ""}
                    readOnly
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "4px",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "120px 1fr",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>User ID:</span>
                  <input
                    type="text"
                    value={binanceResponse._id || ""}
                    readOnly
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "4px",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Bybit Form */}
      <div style={{ maxWidth: "28rem", margin: "32px auto 0" }}>
        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            Bybit API Key
          </label>
          <input
            type="text"
            value={bybitApiKey}
            onChange={(e) => setBybitApiKey(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "8px",
              color: "#374151",
              fontWeight: "500",
            }}
          >
            Bybit Secret Key
          </label>
          <input
            type="text"
            value={bybitSecretKey}
            onChange={(e) => setBybitSecretKey(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              outline: "none",
            }}
          />
        </div>
        <button
          onClick={handleBybitSubmit}
          style={{
            backgroundColor: "#2563eb",
            color: "white",
            padding: "8px 24px",
            borderRadius: "6px",
            fontWeight: "500",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
            transition: "background-color 150ms ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#1d4ed8")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#2563eb")}
        >
          Check Bybit Keys
        </button>
        {showBybitResponse && (
          <div
            style={{
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#f3f4f6",
              borderRadius: "4px",
            }}
          >
            {bybitResponse && (
              <div style={{ display: "grid", gap: "8px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "120px 1fr",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>User Email:</span>
                  <input
                    type="text"
                    value={bybitResponse.email || ""}
                    readOnly
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "4px",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "120px 1fr",
                    gap: "8px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>User ID:</span>
                  <input
                    type="text"
                    value={bybitResponse._id || ""}
                    readOnly
                    style={{
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "4px",
                      outline: "none",
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeveloperScripts;

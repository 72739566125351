import Pricing from "../sharedPages/app/Pricing";
import React from "react";

const AppPricing = () => {
  return (
    <div className="container">
      <div className="row justify-content-center h-100 support_wrapper">
        <div className="copyTrade">
          <div className="tab-content">
            <div className="AppTab">
              <Pricing />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppPricing;

import "./MemberStats.css";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import useApi from "../../../../helpers/apiHelper/requestHelper.js";

const MemberStats = () => {
  const [stats, setStats] = useState({});

  const api = useApi();
  const user = useSelector((value) => value?.user?.user);

  const participants = user?.leaderCommunity?.participants || [];

  const totalAccountsSize = participants.reduce(
    (total, participant) =>
      total +
      ((participant?.binanceBalance?.balance || 0) +
        (participant?.binanceBalance?.coinFutureBalance || 0) +
        (participant?.bybitBalance?.balance || 0)),
    0
  );

  const averageAccountSize =
    Math.round(totalAccountsSize / participants.length) || 0;

  const getUserStats = async () => {
    const response = await api("get", "stats/index");
    if (response?.status) setStats(response?.data);
  };

  useEffect(() => {
    getUserStats();
  }, []);

  return (
    <>
      <div className="memberStatBox">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <div className="statItem">
              <p className="title">Total subscribers</p>
              <p className="statNumber">{participants.length || 0}</p>
              <p className="subText">
                <span className="text-green">
                  +{stats?.userGrowthInWeekNumber || 0}%
                </span>{" "}
                Since last week
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <div className="statItem mx-lg-auto  ms-md-auto">
              <p className="title">Avg. membership</p>
              <p className="statNumber">
                {stats?.membersMonthAvg || 0} <span>months</span>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <div className="statItem mx-lg-auto">
              <p className="title">Most popular tier</p>
              <p className="statNumber">$99</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <div className="statItem ms-lg-auto ms-md-auto">
              <p className="title">Avg. Account Size</p>
              <p className="statNumber">${averageAccountSize}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberStats;

import appIcon from "../../../assets/images/Smartphone.svg";
import businessIcon from "../../../assets/images/Money.svg";
import promoteIcon from "../../../assets/images/Announce.svg";

export const leaderSideBarItems = [
  {
    label: "Dashboard",
    url: "/leader/dashboard",
    isDropDown: false,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24.279" height="23.167">
        <path
          d="M24.055 10.453 12.63.235a.32.32 0 0 0-.065-.05.676.676 0 0 0-.914-.014L.225 10.388a.676.676 0 0 0 .899 1.009l2.057-1.843V21.469a1.7 1.7 0 0 0 1.698 1.698h3.606c.879 0 1.593-.714 1.593-1.593v-6.058c0-.135.11-.244.244-.244h3.64c.136 0 .246.11.246.244v6.058c0 .879.714 1.593 1.593 1.593h3.605c.949 0 1.698-.934 1.698-2.128V9.618l2.057 1.843a.676.676 0 0 0 .954-.055.687.687 0 0 0-.06-.953Zm-4.304 3.33v7.246c0 .485-.24.78-.35.78h-3.605a.245.245 0 0 1-.245-.245v-6.048c0-.879-.714-1.593-1.593-1.593h-3.64c-.88 0-1.594.714-1.594 1.593v6.058c0 .135-.11.244-.244.244H4.874a.35.35 0 0 1-.35-.35V8.346l7.581-6.771 7.646 6.836v5.373Z"
          data-name="Path 36"
          fill-rule="evenodd"
        />
      </svg>
    ),
  },
  {
    label: "Copy Trade",
    url: "/leader/newCopyTrade",
    isDropDown: false,
    icon: (
      <svg
        height="18.966"
        viewBox="0 0 18.966 18.966"
        width="18.966"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-6.25 -6.25)">
          <path
            d="M13.7,20.474H8.282A2.031,2.031,0,0,1,6.25,18.443V8.282A2.032,2.032,0,0,1,8.282,6.25h14.9a2.032,2.032,0,0,1,2.032,2.032V18.443a2.031,2.031,0,0,1-2.032,2.031H17.765v3.387H19.8a.677.677,0,1,1,0,1.355H11.669a.677.677,0,0,1,0-1.355H13.7Zm2.709,3.387V20.474H15.056v3.387ZM8.282,19.12h14.9a.677.677,0,0,0,.677-.677V8.282a.677.677,0,0,0-.677-.677H8.282a.677.677,0,0,0-.677.677V18.443A.677.677,0,0,0,8.282,19.12Zm12.771-7.19-1.98,3.462a.676.676,0,0,1-1.061.148l-1.542-1.508-2.241,2.8a.678.678,0,0,1-1.008.056l-1.473-1.474-1.521,2.073a.677.677,0,1,1-1.092-.8l1.988-2.709a.672.672,0,0,1,.494-.274.679.679,0,0,1,.531.2l1.5,1.5,2.236-2.8a.678.678,0,0,1,1-.062l1.45,1.419,1.6-2.806-.489.148a.678.678,0,1,1-.392-1.3L21,9.415a.677.677,0,0,1,.844.453l.588,1.945a.678.678,0,0,1-1.3.392Z"
            fill-rule="evenodd"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    ),
  },
  // {
  //   label: "Onboarding info",
  //   url: "/leader/onboardingUser",
  //   isDropDown: false,
  //   icon: (
  //     <svg
  //       className="onboardingSVG"
  //       height="20.883"
  //       width="20.883"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g data-name="Group 16180" clip-path="url(#a)">
  //         <path
  //           d="M19.234 20.883H1.65A1.65 1.65 0 0 1 0 19.234V4.214a.55.55 0 1 1 1.1 0v15.02c0 .304.245.55.549.55h17.585a.55.55 0 0 0 .55-.55V6.411a.55.55 0 1 1 1.099 0v12.823c0 .91-.74 1.649-1.649 1.649Z"
  //           data-name="Path 10793"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M5.495 12.457a3.301 3.301 0 0 1-3.297-3.298 3.301 3.301 0 0 1 3.297-3.297A3.301 3.301 0 0 1 8.793 9.16a3.301 3.301 0 0 1-3.298 3.298m0-5.496a2.201 2.201 0 0 0-2.198 2.198c0 1.212.987 2.198 2.198 2.198A2.201 2.201 0 0 0 7.693 9.16a2.201 2.201 0 0 0-2.198-2.198"
  //           data-name="Path 10794"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M7.438 11.652a.548.548 0 0 1-.388-.16L5.107 9.547a.548.548 0 0 1-.161-.389V6.412a.55.55 0 0 1 1.099 0v2.52l1.782 1.782a.55.55 0 0 1-.39.938Z"
  //           data-name="Path 10795"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M18.135 11.724h-7.694a.55.55 0 0 1-.55-.55v-4.03a.55.55 0 0 1 .55-.55h7.694a.55.55 0 0 1 .55.55v4.03a.55.55 0 0 1-.55.55m-7.144-1.1h6.594v-2.93h-6.594v2.93Z"
  //           data-name="Path 10796"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M13.372 18.685H2.748a.55.55 0 0 1-.55-.55V13.74a.55.55 0 1 1 1.1 0v3.847h10.075a.55.55 0 0 1 0 1.099Z"
  //           data-name="Path 10797"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M4.68 18.685a.55.55 0 0 1-.55-.55v-1.832a.55.55 0 0 1 1.099 0v1.832a.55.55 0 0 1-.55.55"
  //           data-name="Path 10798"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M6.61 18.685a.55.55 0 0 1-.549-.55v-3.297a.55.55 0 0 1 1.1 0v3.297a.55.55 0 0 1-.55.55"
  //           data-name="Path 10799"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M8.543 18.685a.55.55 0 0 1-.55-.55V13.74a.55.55 0 0 1 1.1 0v4.396a.55.55 0 0 1-.55.55"
  //           data-name="Path 10800"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M10.475 18.685a.55.55 0 0 1-.55-.55v-2.93a.55.55 0 0 1 1.1 0v2.93a.55.55 0 0 1-.55.55"
  //           data-name="Path 10801"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M12.406 18.685a.55.55 0 0 1-.55-.55l.001-4.396a.55.55 0 1 1 1.1 0l-.001 4.396a.55.55 0 0 1-.55.55"
  //           data-name="Path 10802"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M18.135 14.655H15.57a.55.55 0 0 1 0-1.1h2.565a.55.55 0 0 1 0 1.1"
  //           data-name="Path 10803"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M18.135 16.67H15.57a.55.55 0 0 1 0-1.1h2.565a.55.55 0 0 1 0 1.1"
  //           data-name="Path 10804"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M18.135 18.685H15.57a.55.55 0 0 1 0-1.1h2.565a.55.55 0 0 1 0 1.1"
  //           data-name="Path 10805"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M20.333 4.763H.55a.55.55 0 0 1-.55-.55V1.648C0 .74.74 0 1.649 0h17.585c.91 0 1.649.74 1.649 1.648v2.565a.55.55 0 0 1-.55.55ZM1.1 3.663h18.685V1.65a.55.55 0 0 0-.55-.55H1.65a.55.55 0 0 0-.55.55v2.015Z"
  //           data-name="Path 10806"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M3.48 2.931h-.732a.55.55 0 0 1 0-1.1h.732a.55.55 0 1 1 0 1.1"
  //           data-name="Path 10807"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M6.411 2.931H5.68a.55.55 0 0 1 0-1.1h.732a.55.55 0 1 1 0 1.1"
  //           data-name="Path 10808"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //         <path
  //           d="M9.342 2.931H8.61a.55.55 0 0 1 0-1.1h.733a.55.55 0 1 1 0 1.1"
  //           data-name="Path 10809"
  //           fill-rule="evenodd"
  //           fill="#000"
  //         />
  //       </g>
  //       <defs>
  //         <clipPath id="a">
  //           <path d="M0 0h20.883v20.883H0V0z" data-name="Rectangle 2927" />
  //         </clipPath>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   label: "Create Promo",
  //   url: "/leader/createPromo",
  //   isDropDown: false,
  //   icon: <img alt="" src={promoteIcon} />,
  // },
  {
    label: "Trading",
    url: "#",
    isDropDown: true,
    icon: (
      <svg
        height="15.386"
        viewBox="-1 0.021 24 15.386"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.922.655a1.03 1.03 0 0 0-.219-.334c-.006-.006-.012-.007-.017-.012a1.027 1.027 0 0 0-.311-.21.993.993 0 0 0-.298-.061c-.03-.002-.055-.017-.085-.017h-8.068c-.557 0-1.008.46-1.008 1.027s.45 1.026 1.008 1.026h5.643l-7.982 8.171-3.037-3.958a1.002 1.002 0 0 0-.72-.392.988.988 0 0 0-.769.278l-7.744 7.463a1.04 1.04 0 0 0-.04 1.452 1.002 1.002 0 0 0 1.427.039L7.64 8.44l3.059 3.985a1.001 1.001 0 0 0 1.51.093l8.774-8.982v5.723c0 .567.45 1.026 1.009 1.026.557 0 1.008-.459 1.008-1.026V1.047a1.07 1.07 0 0 0-.078-.392Z"
          data-name="Shape"
          fill-rule="evenodd"
        />
      </svg>
    ),
    subItems: [
      { label: "Overview", url: "/leader/overview" },
      { label: "Open Trades", url: "/leader/liveTrade" },
      { label: "Closed Trades", url: "/leader/closedTrade" },
    ],
  },
  {
    label: "Members",
    url: "/leader/memberListening",
    isDropDown: false,
    icon: (
      <svg
        className="memberSvg"
        height="17.924"
        viewBox="0 0 21.555 17.924"
        width="21.555"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-47.124 -343.927)">
          <g transform="translate(56.374 344.666)">
            <path
              d="M36.4,16.186a3.093,3.093,0,1,1,3.065-3.093A3.093,3.093,0,0,1,36.4,16.186Zm0-5.44a2.346,2.346,0,1,0,2.318,2.346A2.346,2.346,0,0,0,36.4,10.747Z"
              stroke-width="0.5"
              stroke="#000"
              transform="translate(-30.373 -10)"
            />
            <path
              d="M28.625,57.825a6.564,6.564,0,0,1-1.689-.373,11.126,11.126,0,0,0-3.2-.61,12.745,12.745,0,0,0-3.211.528c-1.107.289-1.906.5-2.417.1a1.173,1.173,0,0,1-.4-1,6.028,6.028,0,0,1,12.055,0,1.328,1.328,0,0,1-.444,1.154,1.12,1.12,0,0,1-.7.2Zm-4.887-1.73a11.646,11.646,0,0,1,3.414.644c.735.222,1.493.452,1.725.284.091-.069.142-.265.142-.56a5.281,5.281,0,0,0-10.562,0c0,.123.015.34.108.413.222.172,1.01-.034,1.773-.233A13.544,13.544,0,0,1,23.738,56.094Z"
              stroke-width="0.5"
              stroke="#000"
              transform="translate(-17.71 -42.891)"
            />
          </g>
          <path
            className="memberInnerBody"
            d="M1564.156,70.821l-4.271,1.592-1.188,2.966-.263,1.925,5.986-.889,5.831,1.224-.257-3.355-3.078-2.694Z"
            fill="#fff"
            transform="translate(-1510 283)"
          />
          <path
            d="M36.91,17.2a3.6,3.6,0,1,1,3.565-3.6A3.6,3.6,0,0,1,36.91,17.2Zm0-6.327a2.729,2.729,0,1,0,2.7,2.729A2.729,2.729,0,0,0,36.91,10.869Z"
            stroke-width="0.5"
            stroke="#000"
            transform="translate(17.475 334.229)"
          />
          <path
            d="M30.405,59.029A7.634,7.634,0,0,1,28.44,58.6a12.941,12.941,0,0,0-3.719-.71,14.824,14.824,0,0,0-3.735.614c-1.288.337-2.217.578-2.812.117a1.364,1.364,0,0,1-.465-1.166,7.011,7.011,0,0,1,14.022,0,1.544,1.544,0,0,1-.517,1.342,1.3,1.3,0,0,1-.81.237Zm-5.684-2.013a13.546,13.546,0,0,1,3.971.749c.855.258,1.737.525,2.006.33.106-.08.165-.308.165-.651a6.142,6.142,0,0,0-12.285,0c0,.143.017.4.126.48.258.2,1.175-.039,2.063-.271A15.754,15.754,0,0,1,24.721,57.017Z"
            stroke-width="0.5"
            stroke="#000"
            transform="translate(29.664 302.57)"
          />
        </g>
      </svg>
    ),
  },
  {
    label: "Business",
    url: "#",
    isDropDown: true,
    icon: <img alt="" src={businessIcon} />,
    subItems: [
      { label: "Performance", url: "/leader/performance" },
      { label: "Reports", url: "/leader/reports" },
      { label: "Payment", url: "/leader/payment" },
      { label: "Payout history", url: "/leader/payoutHistory" },
    ],
  },
  {
    label: "App",
    url: "#",
    isDropDown: true,
    icon: <img alt="" src={appIcon} />,
    subItems: [
      { label: "About the App", url: "/leader/app" },
      { label: "Pricing", url: "/leader/appPricing" },
      { label: "Promote App", url: "/leader/promoteApp" },
      { label: "Order", url: "/leader/orderApp" },
    ],
  },
];

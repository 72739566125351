import "./AutoShare.css";
import { setLoading } from "../../../../redux/loading/loadingSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { updateUser } from "../../../../redux/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import check from "../../../../assets/images/check.svg";
import discord from "../../../../assets/images/discord.svg";
import FloatingDropdownWithArrow from "../../CommonComponents/FloatingDropdownWithArrow/FloatingDropdownWithArrow";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import telegram from "../../../../assets/images/telegram.jpg";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import xIcon from "../../../../assets/images/x.jpg";
import BubbleSvgButton from "../../CommonComponents/BubbleSvgButton/BubbleSvgButton";

const AutoShare = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((value) => value?.user?.user);

  const [connectedSocial, setConnectedSocial] = useState("");
  const [discordData, setDiscordData] = useState({
    channelName: "",
    serverId: "",
  });
  const [formErrors, setFormErrors] = useState({
    channelName: false,
    chatId: false,
    serverId: false,
  });
  const [isConnected, setIsConnected] = useState(false);
  const [shareTradingResultAs, setShareTradingResultAs] = useState("");
  const [shareTradingResultTime, setShareTradingResultTime] = useState("");
  const [showChannelName, setShowChannelName] = useState(false);
  const [showDisconnectButton, setShowDisconnectButton] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSharedOrders, setShowSharedOrders] = useState(false);
  const [showSharedResultsAs, setShowSharedResultsAs] = useState(false);
  const [socialChannelVaue, selectedSocialChannel] = useState("");
  const [telegramData, setTelegramData] = useState({ chatId: "" });

  const handleSocialChannelChange = (value) => {
    selectedSocialChannel(value);
    setConnectedSocial("");
    setDiscordData({ channelName: "", serverId: "" });
    setIsConnected(false);
    setShareTradingResultAs("");
    setShareTradingResultTime("");
    setShowChannelName(false);
    setShowDisconnectButton(false);
    setShowSaveButton(false);
    setShowSharedOrders(false);
    setShowSharedResultsAs(false);
    setTelegramData({ chatId: "" });
  };

  const handleDiscordButtonClick = () => {
    const discordOAuthURL =
      "https://discord.com/api/oauth2/authorize?client_id=1275052346581323936&permissions=3089&scope=bot";

    window.open(discordOAuthURL, "_blank");
  };

  const handleTelegramButtonClick = () => {
    const CopyyyItBotURL = "https://t.me/CopyTrading_Results_bot";

    window.open(CopyyyItBotURL, "_blank");
  };

  const handleTwitterButtonClick = async () => {
    localStorage.setItem("twitter", "true");

    if (user?.twitterCodeVerifier && user?.twitterRefreshToken) {
      toastify("You have already Authorized with Twitter.", "error");
      return;
    }

    const response = await api("get", "social/twitterOAuth");

    if (response?.status) {
      window.open(response?.data?.url, "_blank");
      setShowSharedOrders(true);
      toastify(response?.message, "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const handleServerIdChange = (e) => {
    const newServerId = e.target.value;

    setDiscordData({
      ...discordData,
      serverId: newServerId,
    });
    setShowChannelName(newServerId.trim() !== "");
  };

  const handleChannelNameChange = (e) => {
    const newChannelName = e.target.value;

    setDiscordData({
      ...discordData,
      channelName: newChannelName,
    });
    setShowSharedOrders(showChannelName && newChannelName.trim() !== "");
  };

  const handleChatIdChange = (e) => {
    const newChatId = e.target.value;

    setTelegramData({
      ...telegramData,
      chatId: newChatId,
    });
    setShowSharedOrders(newChatId.trim() !== "");
  };

  const handleSharedOrdersChange = (value) => {
    setShareTradingResultTime(value);
    setShowSharedResultsAs(value !== "");
  };

  const handleSharedResultsAsChange = (value) => {
    setShareTradingResultAs(value);
    setShowSaveButton(value !== "");
  };

  const handleSaveButton = async () => {
    try {
      if (connectedSocial === "Discord") {
        await saveDiscord();
      } else if (connectedSocial === "Telegram") {
        await saveTelegram();
      }

      const response = await api(
        "post",
        "copyTrade/update-message-permissions",
        {
          isCloseOrdersOnly: shareTradingResultTime?.toLowerCase() === "close",
          platform: connectedSocial?.toLowerCase(),
          sendAsText: shareTradingResultAs?.toLowerCase() === "text",
        }
      );

      if (response?.status) {
        handleSocialChannelChange("");
        toastify(response?.message, "success");
      } else {
        toastify(response?.message, "error");
      }
    } catch (error) {
      console.error("Error in handleSaveButton:", error);
      toastify("Something went wrong!", "error");
    }
  };

  const saveDiscord = async () => {
    if (!discordData?.channelName) {
      toastify("Channel name is missing.", "error");
      throw new Error("Channel name missing");
    }

    if (!discordData?.serverId) {
      toastify("Server ID is missing.", "error");
      throw new Error("Server ID missing");
    }

    dispatch(setLoading(true));

    try {
      const requestBody = {
        communityId: user?.leaderCommunity?._id,
        name: discordData?.channelName,
        serverId: discordData?.serverId,
      };

      const response = await api(
        "post",
        "social/createDiscordChannel",
        requestBody
      );

      if (response?.status) {
        dispatch(updateUser(response?.data));
        setDiscordData({ serverId: "", channelName: "" });
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const saveTelegram = async () => {
    if (!telegramData?.chatId) {
      toastify("Chat id is missing.", "error");
      throw new Error("Chat ID missing");
    }

    dispatch(setLoading(true));

    try {
      const requestBody = {
        chatId: telegramData?.chatId,
        communityId: user?.leaderCommunity?._id,
      };

      const response = await api("post", "social/verifyTelegram", requestBody);

      if (response?.status) {
        dispatch(updateUser(response?.data));
        setTelegramData({ chatId: "" });
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSocialReset = async (platform) => {
    dispatch(setLoading(true));

    const response = await api("post", "social/reset", { platform });

    if (response?.status) {
      dispatch(setLoading(false));
      dispatch(updateUser(response?.data));
      handleSocialChannelChange("");
      toastify(`${platform} disconnected successfully!`, "success");
    } else {
      dispatch(setLoading(false));
      toastify(response?.message);
    }
  };

  useEffect(() => {
    if (user?.leaderCommunity?.socials) {
      const socials = user.leaderCommunity.socials;

      if (socials.discordServerId && socials.discordChannelName) {
        setDiscordData({
          channelName: socials.discordChannelName,
          serverId: socials.discordServerId,
        });

        if (socialChannelVaue === "Discord") {
          setConnectedSocial("Discord");
          setIsConnected(true);
          setShowChannelName(true);
          setShowDisconnectButton(true);
          setShowSharedOrders(true);

          if (user?.messagePermissions?.discord) {
            const userDiscordPermissions = user.messagePermissions.discord;

            setShareTradingResultAs(
              userDiscordPermissions.sendAsText ? "Text" : "Image"
            );
            setShareTradingResultTime(
              userDiscordPermissions.isCloseOrdersOnly
                ? "Close"
                : "Open & Close"
            );
            setShowSharedResultsAs(true);
          }
        }
      }

      if (socials.telegramChatId) {
        setTelegramData({
          chatId: socials.telegramChatId,
        });

        if (socialChannelVaue === "Telegram") {
          setConnectedSocial("Telegram");
          setIsConnected(true);
          setShowDisconnectButton(true);
          setShowSharedOrders(true);

          if (user?.messagePermissions?.telegram) {
            const userTelegramPermissions = user.messagePermissions.telegram;

            setShareTradingResultAs(
              userTelegramPermissions.sendAsText ? "Text" : "Image"
            );
            setShareTradingResultTime(
              userTelegramPermissions.isCloseOrdersOnly
                ? "Close"
                : "Open & Close"
            );
            setShowSharedResultsAs(true);
          }
        }
      }

      if (socials.twitterCodeVerifier) {
        if (socialChannelVaue === "X") {
          setConnectedSocial("X");
          setIsConnected(true);
          setShowDisconnectButton(true);
          setShowSharedOrders(true);

          if (user?.messagePermissions?.twitter) {
            const twitterPermissions = user.messagePermissions.twitter;

            setShareTradingResultAs(
              twitterPermissions.sendAsText ? "Text" : "Image"
            );
            setShareTradingResultTime(
              twitterPermissions.isCloseOrdersOnly ? "Close" : "Open & Close"
            );
            setShowSharedResultsAs(true);
          }
        }
      }
    }
  }, [socialChannelVaue, user]);

  return (
    <>
      <div className="AutoShareSection" id="AutoShare">
        <div className="row align-items-start">
          <div className="col-lg-6 col-md-6 col-12">
            <p className="title">Share your trade entries and results </p>
            <p className="subTitle">
              Select the channels to automatically share to
              <InfoPopup
                contentItems={[
                  {
                    type: "heading",
                    text: "Share your trade entries + results",
                  },
                  {
                    type: "paragraph",
                    text: "Connect the socials you want us to automatically share your results to.",
                  },
                ]}
              />
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="connectedChannelBox mb-20px mb-lg-0 mb-md-0">
              <p className="">Sharing to:</p>
              {(!isConnected || socialChannelVaue === "Discord") && (
                <img alt="discord" src={discord} />
              )}
              {(!isConnected || socialChannelVaue === "Telegram") && (
                <img alt="telegram" src={telegram} />
              )}
              {(!isConnected || socialChannelVaue === "X") && (
                <img alt="xIcon" src={xIcon} />
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-4 col-12">
            <div className="mb-20px fixedWidth1440">
              <FloatingDropdownWithArrow
                id="socialChannelDropdown"
                label="Social Channel"
                options={["Discord", "Telegram", "X"]}
                selectedValue={socialChannelVaue}
                setSelectedValue={handleSocialChannelChange}
              />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-12">
            <div className="mb-20px fixedWidth1440 mx-auto">
              {socialChannelVaue && !showDisconnectButton && (
                <BubbleSvgButton
                  onClick={() => {
                    if (socialChannelVaue === "Discord") {
                      handleDiscordButtonClick();
                    } else if (socialChannelVaue === "Telegram") {
                      handleTelegramButtonClick();
                    } else if (socialChannelVaue === "X") {
                      handleTwitterButtonClick();
                    }
                    setIsConnected(true);
                    setConnectedSocial(socialChannelVaue);
                  }}
                  text={isConnected ? "Connected" : "Connect"}
                  type="button"
                  variant="black"
                  svgIcon={
                    isConnected ? (
                      <img
                        alt="connected icon"
                        src={check}
                        style={{
                          width: "21px",
                          height: "21px",
                        }}
                      />
                    ) : null
                  } // Set to null when not connected
                  svgPosition={isConnected ? "right" : null}
                  justifyContent="center"
                />
              )}
              {showDisconnectButton && (
                <BubbleSvgButton
                  onClick={() => {
                    handleSocialReset(socialChannelVaue);
                  }}
                  text={"Disconnect"}
                  type="button"
                  variant="red"
                  justifyContent="center"
                />
              )}
            </div>
          </div>
          {connectedSocial === "Discord" && (
            <>
              <div className="col-lg-3 col-md-4 col-12">
                <div className="mb-20px fixedWidth1440 textFielRemoveShadow mx-auto">
                  <FloatingTextFiled
                    className={formErrors["serverId"] ? "invalid" : ""}
                    disabled={showDisconnectButton}
                    label="Server ID"
                    onChange={handleServerIdChange}
                    placeholder="Server ID"
                    value={discordData.serverId}
                  />
                </div>
              </div>
              {showChannelName && (
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="mb-20px textFielRemoveShadow fixedWidth1440 ms-auto">
                    <FloatingTextFiled
                      disabled={showDisconnectButton}
                      forLabel="monthly-price"
                      id="monthly-price"
                      label="Channel Name"
                      onChange={handleChannelNameChange}
                      placeholder="Channel Name"
                      value={discordData.channelName}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {connectedSocial === "Telegram" && (
            <div className="col-lg-3 col-md-4 col-12">
              <div className="mb-20px textFielRemoveShadow fixedWidth1440 ms-auto">
                <FloatingTextFiled
                  className={formErrors["chatId"] ? "invalid" : ""}
                  disabled={showDisconnectButton}
                  label="Chat ID"
                  onChange={handleChatIdChange}
                  placeholder="Chat ID"
                  value={telegramData.chatId}
                />
              </div>
            </div>
          )}
          {/* {showSharedOrders && <div className="w-100"></div>} */}
          {showSharedOrders && (
            <div className="col-lg-3 col-md-4 col-12">
              <div
                className={`mb-20px fixedWidth1440 ${
                  socialChannelVaue === "X" && "mx-auto"
                } ${socialChannelVaue === "Telegram" && "ms-auto"}`}
              >
                <FloatingDropdownWithArrow
                  id="SharedOrdersDropdown"
                  label="Shared orders"
                  options={["Close", "Open & Close"]}
                  selectedValue={shareTradingResultTime}
                  setSelectedValue={handleSharedOrdersChange}
                />
              </div>
            </div>
          )}
          {showSharedResultsAs && (
            <div className="col-lg-3 col-md-4 col-12">
              <div
                className={`mb-20px fixedWidth1440 ${
                  socialChannelVaue === "X" ? "ms-auto" : "mx-auto"
                }
                ${socialChannelVaue === "Telegram" && "me-auto"}`}
              >
                <FloatingDropdownWithArrow
                  id="SharedAsDropdown"
                  label="Shared results as"
                  options={
                    socialChannelVaue === "X" ? ["Text"] : ["Text", "Image"]
                  }
                  selectedValue={shareTradingResultAs}
                  setSelectedValue={handleSharedResultsAsChange}
                />
              </div>
            </div>
          )}
          {showSaveButton && (
            <div
              className={`col-lg-3 col-md-4 col-12 ${
                socialChannelVaue === "X"
                  ? "offset-lg-9 offset-md-4"
                  : "offset-lg-3 offset-md-8"
              }
              ${
                socialChannelVaue === "Telegram"
                  ? "offset-lg-6"
                  : "offset-lg-3 offset-md-8"
              }`}
            >
              <div className="mb-20px fixedWidth1440 ms-auto">
                <BubbleSvgButton
                  onClick={handleSaveButton}
                  text={"Save"}
                  type="button"
                  variant="green"
                  justifyContent="center"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AutoShare;

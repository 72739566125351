import "./FloatingTextArea.css";
import React from "react";

const FloatingTextArea = ({
  className,
  forLabel,
  id,
  label,
  onBlur,
  onChange,
  onClick,
  placeholder,
  value,
}) => {
  return (
    <>
      <div className={`CopyItCustomTextArea ${className}`}>
        <div className="form-floating">
          <textarea
            className={`form-control fs15`}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            rows={10}
            value={value}
          />
          <label htmlFor={forLabel}>{label}</label>
        </div>
      </div>
    </>
  );
};

export default FloatingTextArea;

import "./ExchangeConnection.css";
import {
  setBinanceConnecting,
  setLoading,
} from "../../../../redux/loading/loadingSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { updateUser } from "../../../../redux/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Copy from "../../../../assets/images/Copy.svg";
import DeleteMember from "../../../../components/modals/DeleteMember";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import rightAnglePath from "../../../../assets/images/rightAnglePath.svg";
import UpdateAndVerifyAccountOTP from "../../accountSettings/UpdateAndVerifyAccountOTP";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import BubbleSvgButton from "../../CommonComponents/BubbleSvgButton/BubbleSvgButton";

const IP_ADDRESS =
  window.location.hostname.split(".")[0] === "my"
    ? "108.129.27.187"
    : "52.214.92.9";

const ExchangeConnection = () => {
  const [credentials, setCredentials] = useState({
    apiKey: "",
    secretKey: "",
    exchange: "",
  });

  const [isApiKeySelected, setisApiKeySelected] = useState(false);
  const [isCredentialsConnected, setCredentialsConnected] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Exchange");
  const [showModal, setShowModal] = useState({ show: false, buttonClick: "" });
  const [showOtpPopUp, setShowOtpPopUp] = useState({ show: false });

  const api = useApi();
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const user = useSelector((value) => value?.user?.user);

  const setExchangeCredentials = async (exchange) => {
    const data = await getUserCredentials(exchange?.toLowerCase());

    if (data?.status) {
      setCredentials({
        apiKey: data?.apiKey,
        exchange: data?.exchange,
        secretKey: data?.secretKey,
      });
    } else {
      setCredentials({
        apiKey: "",
        exchange,
        secretKey: "",
      });
    }

    return data;
  };

  const handleSelect = (optionText) => {
    const exchange = optionText.split(" ")[0];

    setExchangeCredentials(exchange);
    setisApiKeySelected(optionText.includes("API Keys"));
    setIsDropdownOpen(false);
    setSelectedOption(optionText);
  };

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

  const oauthBinance = async () => {
    let userAgent = navigator?.userAgent;

    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }

    dispatch(setBinanceConnecting(true));

    const clientId = "39vZ9un1Fd";
    const redirect_uri = "https%3A%2F%2Fmy.copyyy.it%2Fsignin";
    const scope = "user:openId,create:apikey";
    const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
    window.location.href = cUrl;
  };

  const oauthBybit = async () => {
    let userAgent = navigator?.userAgent;

    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }

    localStorage.setItem("binanceCondition", true);

    const clientId = "3ccf41551815af4";
    const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
    window.location.href = cUrl;
  };

  const getUserCredentials = async (exchangeKey) => {
    const response = await api(
      "get",
      `binanceCredentials/index/${exchangeKey}`
    );

    const { apiKey, exchange, secretKey } = response?.data || {};

    if (apiKey && exchange && secretKey) {
      return { apiKey, exchange, secretKey, status: true };
    }

    return { status: false };
  };

  const connectManually = async ({ apiKey, secretKey, exchange }) => {
    const response = await api("post", "binanceCredentials/create", {
      apiKey,
      exchange,
      secretKey,
    });

    if (response?.status)
      toastify("Exchange connected successfully.", "success");
    else toastify(response?.message);

    return response;
  };

  const handleConnect = async () => {
    const isQuickConnect =
      selectedOption === "Binance Quick Connect" ||
      selectedOption === "ByBit Quick Connect";

    const exchangeType = selectedOption.split(" ")[0].toLowerCase();

    if (isQuickConnect && credentials?.apiKey && credentials?.secretKey) {
      await connectManually(credentials);
      await fetchUserData();
    } else if (isQuickConnect) {
      exchangeType === "binance" ? oauthBinance() : oauthBybit();
      toastify("Exchange connected successfully.", "success");
    } else {
      await connectManually(credentials);
      await fetchUserData();
    }
  };

  const fetchUserData = async () => {
    const myResponse = await api("get", "users/index");
    if (myResponse) {
      dispatch(updateUser(myResponse?.data?.user));
    }
  };

  const callApi = async () => {
    if (showOtpPopUp?.buttonClick === "Disconnect") {
      let credentialToUpdate = {};
      if (user?.binanceCredentials?.isConnected) {
        credentialToUpdate = {
          binanceCredentials: {
            ...user?.binanceCredentials,
            isConnected: false,
            isConnectedSpot: false,
            isConnectedUsdFuture: false,
          },
          onboarding: {
            ...user.onboarding,
            connect: false,
          },
        };
      }

      if (user?.bybitCredentials?.isConnected) {
        credentialToUpdate = {
          bybitCredentials: {
            ...user?.bybitCredentials,
            isConnected: false,
            isConnectedSpot: false,
            isConnectedDerivatives: false,
          },
          onboarding: {
            ...user.onboarding,
            connect: false,
          },
        };
      }

      const response = await api("post", "users/update", credentialToUpdate);
      if (response?.status) {
        if (user?.role === "leader")
          api("post", "livetrade/closeAllMembersTrade");

        await fetchUserData();
        toastify("Exchange disconnected successfully.", "success");
      } else {
        toastify(response?.message);
      }
    } else if (showOtpPopUp?.buttonClick === "Delete") {
      let credentialToUpdate = {};
      if (user?.binanceCredentials?.isConnected) {
        credentialToUpdate = {
          binanceCredentials: {},
          uniqueIds: {},
        };
      }
      if (user?.bybitCredentials?.isConnected) {
        credentialToUpdate = {
          bybitCredentials: {},
        };
      }
      await api("post", "users/update", credentialToUpdate);
      await fetchUserData();

      toastify("Exchange deleted successfully", "success");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      user?.bybitCredentials?.isConnected ||
      user?.binanceCredentials?.isConnected
    ) {
      setCredentialsConnected(true);
      setisApiKeySelected(true);
      if (user?.binanceCredentials?.isConnected) {
        setSelectedOption("Binance API Keys");
        setExchangeCredentials("binance");
      } else if (user?.bybitCredentials?.isConnected) {
        setSelectedOption("ByBit API Keys");
        setExchangeCredentials("bybit");
      }
    } else {
      setCredentialsConnected(false);
      setisApiKeySelected(false);
      setSelectedOption("Select Exchange");
    }
  }, [user]);

  const handleApiKeyChange = (e) => {
    setCredentials({
      ...credentials,
      apiKey: e.target.value,
    });
  };

  const handleSecretKeyChange = (e) => {
    setCredentials({
      ...credentials,
      secretKey: e.target.value,
    });
  };

  return (
    <div className="exchangeBox" id="ExchangeConnection">
      <div className="row align-items-center">
        <div className="col-12">
          <p className="title">Exchange Connection</p>
          <p className="subTitle">
            Connect the trading account your members will copy
            <InfoPopup
              contentItems={[
                { text: "Connected Trading Account", type: "heading" },
                {
                  text: "Your members are automatically copy trading everything you trade from your connected trading account <br/> <br/>",
                  type: "paragraph",
                },
                {
                  text: "If you want to switch exchange account",
                  type: "heading",
                },
                {
                  text: "Disconnect your connected exchange account and choose another exchange. Please make sure to close your trades so your subscribers won't be affected by your changes.<br/>",
                  type: "paragraph",
                },
              ]}
            />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-4 col-12">
          <div className="customDropdown mb-16px" ref={dropdownRef}>
            <button
              className={`dropdownButton fixedWidth1440 ${
                isDropdownOpen ? "active" : ""
              }`}
              disabled={isCredentialsConnected}
              onClick={toggleDropdown}
              ref={buttonRef}
            >
              <p>
                <span
                  className={`btnSpanOne ${
                    selectedOption === "Select Exchange" && "opacity40"
                  }`}
                >
                  {selectedOption === "Select Exchange"
                    ? selectedOption
                    : selectedOption.split(" ")[0]}
                </span>
                <span className="btnSpan">
                  {selectedOption === "Select Exchange"
                    ? ""
                    : selectedOption.split(" ").splice(1).join(" ")}
                </span>
              </p>
              <span
                className={`dropdownArrow ${isDropdownOpen && "rotate"}`}
                style={{ opacity: selectedOption !== "Select Exchange" && 1 }}
              >
                <img alt="" src={dropdownAngle} />
              </span>
            </button>
            {isDropdownOpen && (
              <div className="dropdownContent">
                <p onClick={() => handleSelect("Binance Quick Connect")}>
                  <span className="span1">Binance</span>
                  <span>Quick Connect</span>
                  <span className="arrowspan">
                    <img src={rightAnglePath} alt="arrow" />
                  </span>
                </p>
                <p onClick={() => handleSelect("Binance API Keys")}>
                  <span className="span1">Binance</span>
                  <span>API Keys</span>
                  <span className="arrowspan">
                    <img src={rightAnglePath} alt="arrow" />
                  </span>
                </p>
                <p onClick={() => handleSelect("ByBit Quick Connect")}>
                  <span className="span1">ByBit</span>
                  <span>Quick Connect</span>
                  <span className="arrowspan">
                    <img src={rightAnglePath} alt="arrow" />
                  </span>
                </p>
                <p onClick={() => handleSelect("ByBit API Keys")}>
                  <span className="span1">ByBit</span>
                  <span>API Keys</span>
                  <span className="arrowspan">
                    <img src={rightAnglePath} alt="arrow" />
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        {isApiKeySelected && (
          <>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="fixedWidth1440 mx-auto mb-16px">
                <FloatingTextFiled
                  disabled={isCredentialsConnected}
                  forLabel="apiKey"
                  id="apiKey"
                  label="API Key"
                  onChange={handleApiKeyChange}
                  placeholder="API Key"
                  type="text"
                  value={
                    isCredentialsConnected
                      ? "*************************"
                      : credentials.apiKey
                  }
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="fixedWidth1440 mx-auto mb-16px">
                <FloatingTextFiled
                  className=" fixedWidth1440"
                  disabled={isCredentialsConnected}
                  forLabel="secretKey"
                  id="secretKey"
                  label="Secret Key"
                  onChange={handleSecretKeyChange}
                  placeholder="Secret Key"
                  type="text"
                  value={
                    isCredentialsConnected
                      ? "*************************"
                      : credentials.secretKey
                  }
                />
              </div>
            </div>
          </>
        )}
        {!isCredentialsConnected && selectedOption !== "Select Exchange" && (
          <>
            {selectedOption === "Binance API Keys" && (
              <div className="col-lg-3 col-md-4 col-12">
                <div className="fixedWidth1440 mb-16px ms-auto copyIpBox">
                  <FloatingTextFiled
                    className="ipFiled fixedWidth1440"
                    disabled={true}
                    forLabel="binanceIp"
                    id="binanceIp"
                    label="IP"
                    onChange={() => {}}
                    placeholder="IP"
                    value={IP_ADDRESS}
                  />
                  <img
                    alt=""
                    className="copyIp"
                    onClick={() => {
                      navigator.clipboard.writeText(IP_ADDRESS).then(() => {
                        toastify("IP Address copied to clipboard!", "success");
                      });
                    }}
                    src={Copy}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-3 col-md-4 col-12">
              <div
                className={`fixedWidth1440 ${
                  selectedOption.includes("Quick Connect") && "mx-auto"
                }
                ${
                  selectedOption === "Binance API Keys" ? "me-auto" : "ms-auto"
                }`}
              >
                <BubbleSvgButton
                  onClick={handleConnect}
                  text={"Connect"}
                  type="button"
                  variant="green"
                  justifyContent="center"
                />
              </div>
            </div>
          </>
        )}

        {isCredentialsConnected && (
          <>
            <div className="col-lg-3 col-md-4 col-12">
              <div
                className={`mb-16px fixedWidth1440 ${
                  selectedOption.includes("Quick Connect")
                    ? "mx-auto"
                    : "ms-auto"
                }`}
              >
                <BubbleSvgButton
                  onClick={() => {
                    setShowModal({ buttonClick: "Disconnect", show: true });
                  }}
                  text={"Disconnect"}
                  type="button"
                  variant="red"
                  justifyContent="center"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div
                className={`fixedWidth1440  ${
                  selectedOption.includes("Quick Connect")
                    ? "mx-auto"
                    : "me-auto"
                }`}
              >
                <BubbleSvgButton
                  onClick={() => {
                    setShowModal({ buttonClick: "Delete", show: true });
                  }}
                  text={"Delete API"}
                  type="button"
                  variant="black"
                  justifyContent="center"
                />
              </div>
            </div>
          </>
        )}
      </div>

      {showOtpPopUp?.show && (
        <UpdateAndVerifyAccountOTP
          callApi={callApi}
          setShowOtpPopUp={setShowOtpPopUp}
        />
      )}

      {showModal.show && (
        <DeleteMember
          anotherText={
            showModal?.buttonClick === "Delete"
              ? ""
              : "Do you want to close all members trades and disconnect?"
          }
          noAction={() => {
            setShowModal({ show: false, buttonClick: "" });
          }}
          subtext={
            showModal?.buttonClick === "Delete"
              ? "Next time you'll need to manually add your Api keys to connect your account"
              : "Your subscribers trades will be closed and your copy trading service won't work."
          }
          text={
            showModal?.buttonClick === "Delete"
              ? "Are you Sure you want to delete your keys permanantly"
              : "You're about to disconnect your exchange"
          }
          yesAction={() => {
            setShowOtpPopUp({
              show: true,
              buttonClick: showModal?.buttonClick,
            });
            setShowModal({ buttonClick: "", show: false });
          }}
        />
      )}
    </div>
  );
};

export default ExchangeConnection;

import { useRef, forwardRef } from "react";
import "./BubbleSvgButton.css";

const BubbleSvgButton = forwardRef(
  (
    {
      className = "",
      disabled = false,
      id,
      isDropdown = false,
      isDropdownOpen = false,
      onClick,
      svgIcon,
      svgPosition = "left",
      text = "Click Me",
      type = "button",
      variant = "black",
      justifyContent = "start",
      mainDivStyle = {},
    },
    forwardedRef
  ) => {
    const circleRef = useRef(null);
    const internalButtonRef = useRef(null);
    const buttonRef = forwardedRef || internalButtonRef;

    const handleMouseEnter = (e) => {
      if (disabled) return;

      const button = buttonRef.current;
      const circle = circleRef.current;
      const rect = button.getBoundingClientRect();

      const relX = e.clientX - rect.left;
      const relY = e.clientY - rect.top;

      circle.classList.add("explode-circle");
      circle.classList.remove("desplode-circle");
      circle.style.left = `${relX}px`;
      circle.style.top = `${relY}px`;
    };

    const handleMouseLeave = (e) => {
      if (disabled) return;

      const button = buttonRef.current;
      const circle = circleRef.current;
      const rect = button.getBoundingClientRect();

      const relX = e.clientX - rect.left;
      const relY = e.clientY - rect.top;

      circle.classList.add("desplode-circle");
      circle.classList.remove("explode-circle");
      circle.style.left = `${relX}px`;
      circle.style.top = `${relY}px`;
    };

    const DropdownArrow = (
      <svg
        className={`dropdownArrow ${isDropdownOpen ? "open" : ""}`}
        height="4"
        viewBox="202.396 290.814 6 4"
        width="6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m205.396 294.814-3-4h6z"
          data-name="Polygon 6"
          fill="#000"
          fillRule="evenodd"
        />
      </svg>
    );

    return (
      <div className="button_su" style={mainDivStyle}>
        <span ref={circleRef} className={`su_button_circle ${variant}`}></span>
        <button
          className={`button_su_inner ${variant} ${type} ${className}`}
          disabled={disabled}
          id={id}
          onClick={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={buttonRef}
          type={type}
        >
          <span
            className="button_content_container"
            style={{
              justifyContent,
            }}
          >
            {svgIcon && svgPosition === "left" && (
              <span className="svg_container">{svgIcon}</span>
            )}
            <span className="button_text_container">{text}</span>
            {svgIcon && svgPosition === "right" && (
              <span className="svg_container">{svgIcon}</span>
            )}
            {isDropdown && (
              <span className="dropdown_arrow_container">{DropdownArrow}</span>
            )}
          </span>
        </button>
      </div>
    );
  }
);

export default BubbleSvgButton;

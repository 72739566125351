import dashboardIcon from "../../../assets/images/dashboard.svg";
import copyTradeIcon from "../../../assets/images/newCopyTrade.svg";
import tradingIcon from "../../../assets/images/perform.svg";

export const memberSideBarItems = [
  {
    label: "Dashboard",
    url: "/member/dashboard",
    isDropDown: false,
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24.279" height="23.167"><path d="M24.055 10.453 12.63.235a.32.32 0 0 0-.065-.05.676.676 0 0 0-.914-.014L.225 10.388a.676.676 0 0 0 .899 1.009l2.057-1.843V21.469a1.7 1.7 0 0 0 1.698 1.698h3.606c.879 0 1.593-.714 1.593-1.593v-6.058c0-.135.11-.244.244-.244h3.64c.136 0 .246.11.246.244v6.058c0 .879.714 1.593 1.593 1.593h3.605c.949 0 1.698-.934 1.698-2.128V9.618l2.057 1.843a.676.676 0 0 0 .954-.055.687.687 0 0 0-.06-.953Zm-4.304 3.33v7.246c0 .485-.24.78-.35.78h-3.605a.245.245 0 0 1-.245-.245v-6.048c0-.879-.714-1.593-1.593-1.593h-3.64c-.88 0-1.594.714-1.594 1.593v6.058c0 .135-.11.244-.244.244H4.874a.35.35 0 0 1-.35-.35V8.346l7.581-6.771 7.646 6.836v5.373Z" fill-rule="evenodd" data-name="Path 36" /></svg>,
  },
  {
    label: "Copy Trade",
    url: "/member/copyTrade2",
    isDropDown: false,
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="18.966" height="18.966" viewBox="0 0 18.966 18.966"><g transform="translate(-6.25 -6.25)"><path d="M13.7,20.474H8.282A2.031,2.031,0,0,1,6.25,18.443V8.282A2.032,2.032,0,0,1,8.282,6.25h14.9a2.032,2.032,0,0,1,2.032,2.032V18.443a2.031,2.031,0,0,1-2.032,2.031H17.765v3.387H19.8a.677.677,0,1,1,0,1.355H11.669a.677.677,0,0,1,0-1.355H13.7Zm2.709,3.387V20.474H15.056v3.387ZM8.282,19.12h14.9a.677.677,0,0,0,.677-.677V8.282a.677.677,0,0,0-.677-.677H8.282a.677.677,0,0,0-.677.677V18.443A.677.677,0,0,0,8.282,19.12Zm12.771-7.19-1.98,3.462a.676.676,0,0,1-1.061.148l-1.542-1.508-2.241,2.8a.678.678,0,0,1-1.008.056l-1.473-1.474-1.521,2.073a.677.677,0,1,1-1.092-.8l1.988-2.709a.672.672,0,0,1,.494-.274.679.679,0,0,1,.531.2l1.5,1.5,2.236-2.8a.678.678,0,0,1,1-.062l1.45,1.419,1.6-2.806-.489.148a.678.678,0,1,1-.392-1.3L21,9.415a.677.677,0,0,1,.844.453l.588,1.945a.678.678,0,0,1-1.3.392Z" transform="translate(0 0)" fill-rule="evenodd" /></g></svg>,
  },
  {
    label: "Trading",
    url: "#",
    isDropDown: true,
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.386" viewBox="-1 0.021 24 15.386"><path d="M22.922.655a1.03 1.03 0 0 0-.219-.334c-.006-.006-.012-.007-.017-.012a1.027 1.027 0 0 0-.311-.21.993.993 0 0 0-.298-.061c-.03-.002-.055-.017-.085-.017h-8.068c-.557 0-1.008.46-1.008 1.027s.45 1.026 1.008 1.026h5.643l-7.982 8.171-3.037-3.958a1.002 1.002 0 0 0-.72-.392.988.988 0 0 0-.769.278l-7.744 7.463a1.04 1.04 0 0 0-.04 1.452 1.002 1.002 0 0 0 1.427.039L7.64 8.44l3.059 3.985a1.001 1.001 0 0 0 1.51.093l8.774-8.982v5.723c0 .567.45 1.026 1.009 1.026.557 0 1.008-.459 1.008-1.026V1.047a1.07 1.07 0 0 0-.078-.392Z" fill-rule="evenodd" data-name="Shape" /></svg>,

    subItems: [
      { label: "Open Trades", url: "/member/liveTrade" },
      { label: "Closed Trades", url: "/member/closedTrade" },
    ],
  },
  {
    label: "Subscription & Billing",
    url: "/member/subscriptionAndBilling",
    isDropDown: false,
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15.386" viewBox="-1 0.021 24 15.386"><path d="M22.922.655a1.03 1.03 0 0 0-.219-.334c-.006-.006-.012-.007-.017-.012a1.027 1.027 0 0 0-.311-.21.993.993 0 0 0-.298-.061c-.03-.002-.055-.017-.085-.017h-8.068c-.557 0-1.008.46-1.008 1.027s.45 1.026 1.008 1.026h5.643l-7.982 8.171-3.037-3.958a1.002 1.002 0 0 0-.72-.392.988.988 0 0 0-.769.278l-7.744 7.463a1.04 1.04 0 0 0-.04 1.452 1.002 1.002 0 0 0 1.427.039L7.64 8.44l3.059 3.985a1.001 1.001 0 0 0 1.51.093l8.774-8.982v5.723c0 .567.45 1.026 1.009 1.026.557 0 1.008-.459 1.008-1.026V1.047a1.07 1.07 0 0 0-.078-.392Z" fill-rule="evenodd" data-name="Shape" /></svg>,
  },
];

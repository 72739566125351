import "./AccountDetail.css";
import { setLoading } from "../../../../redux/loading/loadingSlice";
import { storeUser } from "../../../../redux/users/usersSlice";
import { toastify } from "../../../../helpers/toast/toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import copyIcon from "../../../../assets/images/Copy.svg";
import editIcon from "../../../../assets/images/edit.svg";
import eyeBlockIcon from "../../../../assets/images/eye-block.svg";
import eyeIcon from "../../../../assets/images/eye.svg";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import React, { useEffect, useRef, useState } from "react";
import UpdateAndVerifyAccountOTP from "../../accountSettings/UpdateAndVerifyAccountOTP";
import useApi from "../../../../helpers/apiHelper/requestHelper";

const AccountDetail = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);
  const [userRole, setUserRole] = useState("");
  const [changedFields, setChangedFields] = useState({
    email: false,
    name: false,
  });
  const [data, setData] = useState({ email: "", name: "" });
  const [editableField, setEditableField] = useState(null);
  const [originalData, setOriginalData] = useState({
    email: "",
    name: "",
  });
  const [show, setShow] = useState(null);
  const [showOtpPopUp, setShowOtpPopUp] = useState({
    resetState: true,
    show: false,
  });

  const communityLink = `${document.location.origin.replace(
    document.location.pathname,
    ""
  )}/community/${user?.leaderCommunity?.name?.split(" ")?.join("")}`;

  const inputRefs = useRef({});

  const callApi = async () => {
    if (changedFields.name) setShow(true);
    else save();
  };

  const fetchUserData = async () => {
    const response = await api("get", "users/index");

    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }

    return response?.data;
  };

  const handleEditClick = (fieldId) => {
    setEditableField(fieldId);

    setTimeout(() => {
      if (inputRefs.current[fieldId]) inputRefs.current[fieldId].focus();
    }, 100);
  };

  const save = async () => {
    dispatch(setLoading(true));
    setShow(null);

    let changes = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const uppercaseRegex = /[A-Z]/;

    if (changedFields.email && !emailRegex.test(data.email)) {
      dispatch(setLoading(false));

      return toastify("Please enter a valid email address");
    }

    if (changedFields.email) changes = { ...changes, email: data.email };
    if (changedFields.name)
      changes = {
        ...changes,
        communityId: user.leaderCommunity._id,
        name: data.name,
      };

    if (Object.keys(changes).length > 0) {
      const response = await api(
        "post",
        "users/updateAccountSettings",
        changes
      );

      if (response?.status) {
        toastify("Account information updated successfully.", "success");

        if (changedFields.email) {
          dispatch(storeUser({ token: null, user: null }));
          localStorage.clear();
          navigate("/login");
        }

        if (changedFields.name) fetchUserData();

        setOriginalData({
          email: changes.email || originalData.email,
          name: changes.name || originalData.name,
        });
        setChangedFields({ email: false, name: false });
        setEditableField(null);
      } else {
        setData({
          ...data,
          email: originalData.email,
          name: originalData.name,
        });
        toastify(response?.message || "Failed to update account information.");
      }
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (user) {
      setData((pre) => ({
        ...pre,
        email: user.email,
        name: user?.leaderCommunity?.name,
      }));
      setOriginalData((pre) => ({
        ...pre,
        email: user.email,
        name: user?.leaderCommunity?.name,
      }));
      setUserRole(user.role);
    }
  }, [user]);

  useEffect(() => {
    setChangedFields({
      email: data.email !== originalData.email,
      name: data.name !== originalData.name,
    });
  }, [originalData, data]);

  return (
    <>
      <div className="accountDetailBox">
        <div className="headingBox">
          <h2 className="title">Account Details</h2>

          <p className="subtitle">
            Please keep your details updated
            {/* <InfoPopup
              contentItems={[
                { text: "Closing your service", type: "heading" },
                {
                  text: "This will allow you to close your copy trading service immediately.",
                  type: "paragraph",
                },
                { text: "Trades", type: "heading" },
                {
                  text: "All active copy trades will be closed when clicking this button.",
                  type: "paragraph",
                },
                { text: "App", type: "heading" },
                {
                  text: "Your app will be closed too. However, you need to remove it from the app stores yourself.",
                  type: "paragraph",
                },
              ]}
            /> */}
          </p>
        </div>

        <div className="row">
          {userRole === "leader" && (
            <div className="col-lg-3 col-md-4 col-12">
              <div className="editable-field fixedWidth1440">
                <FloatingTextFiled
                  className="accdetailinputbox"
                  disabled={editableField !== "community-name"}
                  forLabel="community-name"
                  id="community-name"
                  label="Community Name"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  placeholder="Community Name"
                  ref={(el) => (inputRefs.current["community-name"] = el)}
                  type="text"
                  value={data.name}
                />
                <span
                  className="edit-icon"
                  onClick={() => handleEditClick("community-name")}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                >
                  <img alt="Edit Icon" src={editIcon} />
                </span>
              </div>
            </div>
          )}

          <div className="col-lg-3 col-md-4 col-12">
            <div className="editable-field fixedWidth1440 mx-auto">
              <FloatingTextFiled
                className="accdetailinputbox"
                disabled={editableField !== "email"}
                forLabel="email"
                id="email"
                label="Email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                placeholder="Email"
                ref={(el) => (inputRefs.current["email"] = el)}
                type="email"
                value={data.email}
              />
              <span
                className="edit-icon"
                onClick={() => handleEditClick("email")}
                style={{ cursor: "pointer", marginLeft: "10px" }}
              >
                <img alt="Edit Icon" src={editIcon} />
              </span>
            </div>
          </div>

          {userRole === "leader" && (
            <div className="col-lg-3 col-md-4 col-12 order-lg-4 order-md-4 order-5">
              <div className="editable-field copyField ms-auto fixedWidth1440 ">
                <FloatingTextFiled
                  className="accdetailinputbox"
                  disabled={true}
                  forLabel="tradingUrl"
                  id="tradingUrl"
                  label="Copy Trading URL"
                  placeholder="Copy Trading URL"
                  value={communityLink.split("//")[1]}
                />
                <span
                  className="edit-icon"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(communityLink)
                      .then(() =>
                        toastify(
                          "Community link copied to clipboard!",
                          "success"
                        )
                      )
                      .catch((error) => {
                        console.error("🚀 -> ProfileDropdown -> error:", error);
                        return toastify(
                          "Failed to copy community link.",
                          "error"
                        );
                      })
                  }
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                >
                  <img alt="Edit Icon" src={copyIcon} />
                </span>
              </div>
            </div>
          )}

          {(changedFields.email || changedFields.name) && (
            <div className="col-lg-3 col-md-12 col-12 order-lg-5 order-md-5 order-4">
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="submitbtn"
                  onClick={() => {
                    setShowOtpPopUp({ resetState: false, show: true });
                  }}
                  type="button"
                >
                  Save edits
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showOtpPopUp.show && (
        <UpdateAndVerifyAccountOTP
          callApi={callApi}
          setShowOtpPopUp={setShowOtpPopUp}
        />
      )}

      {show && (
        <ConfirmationModal
          description="If you change your business name your landing page URL will change too!"
          heading="If you change your business"
          noAction={() => {
            setData((pre) => ({ ...pre, name: originalData.name }));
            setShow(null);
            setShowOtpPopUp({ resetState: true, show: false });
          }}
          subheading="name it will affect your service"
          yesAction={save}
        />
      )}
    </>
  );
};

export default AccountDetail;

import React from 'react'
import "./Trading.css"

const TradingListening = () => {
  return (
    <>
      <div className="TradingPage">
        <h2 class="PageTitle text_GB fs20 mt-smLayout-30">
          Trading
        </h2>
        {/* <AccountDetail />
        <AccountSecurity /> */}
      </div>
    </>

  )
}

export default TradingListening
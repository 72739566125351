import PromoteApp from "../sharedPages/app/PromoteApp";
import React from "react";

const Promote = () => {
  return (
    <div className="container">
      <div className="row justify-content-center h-100 support_wrapper">
        <div className="copyTrade">
          <div className="tab-content">
            <div className="AppTab">
              <PromoteApp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promote;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  awaitingResponse: false,
  binanceConnecting: false,
  connectionFailMessage: "",
  isLoading: false,
  reportLoading: false,
  settingUpService: false,
  showConnectionFailModal: false,
  showConnectionSuccessModal: false,
  signingYouIn: false,
};

export const loadingSlice = createSlice({
  initialState,
  name: "loadingSlice",
  reducers: {
    clearLoadingState: () => initialState,
    setBinanceConnecting: (state, action) => {
      state.binanceConnecting = action.payload;
    },
    setConnectionFailMessage: (state, action) => {
      state.connectionFailMessage = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setReportLoading: (state, action) => {
      state.reportLoading = action.payload;
    },
    setResponseLoading: (state, action) => {
      state.awaitingResponse = action.payload;
    },
    setSettingUpService: (state, action) => {
      state.settingUpService = action.payload;
    },
    setShowConnectionFailModal: (state, action) => {
      state.showConnectionFailModal = action.payload;
    },
    setShowConnectionSuccessModal: (state, action) => {
      state.showConnectionSuccessModal = action.payload;
    },
    setSigningYouIn: (state, action) => {
      state.signingYouIn = action.payload;
    },
  },
});

export const {
  clearLoadingState,
  setBinanceConnecting,
  setConnectionFailMessage,
  setLoading,
  setReportLoading,
  setResponseLoading,
  setSettingUpService,
  setShowConnectionFailModal,
  setShowConnectionSuccessModal,
  setSigningYouIn,
} = loadingSlice.actions;

export default loadingSlice.reducer;

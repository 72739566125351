import React, { useEffect, useRef, useState } from "react";
import Binance from "../../../assets/images/Binance_black.png";
import BayBit from "../../../assets/images/Bybit_black.png";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setResponseLoading,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
  setConnectionFailMessage,
  setBinanceConnecting,
} from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { updateUser } from "../../../redux/users/usersSlice";
import ExchangeConnects from "./ExchangeConnects";
import copyIcon from "../../../assets/images/Copy.svg";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import RenderLinks from "../../../components/links/RenderLinks";
import AuthLoader from "../../../components/loader/authLoader";
import DeleteMember from "../../../components/modals/DeleteMember";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import UpdateAndVerifyAccountOTP from "../accountSettings/UpdateAndVerifyAccountOTP";

const HOSTNAME = window.location.hostname;

export const wifiDisconnect = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.173"
      height="15.792"
      viewBox="636 330.473 19.173 15.792"
    >
      <g
        data-name="Group 2"
        transform="translate(636 330.472)"
        clip-path='url("#a")'
      >
        <path
          d="M11.773 13.43a.852.852 0 0 0 .596-1.46 3.888 3.888 0 0 0-2.235-1.1.852.852 0 1 0-.217 1.69c.477.065.92.286 1.26.627a.85.85 0 0 0 .596.244"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 1"
        />
        <path
          d="M13.947 11.256a.852.852 0 0 0 .608-1.45 6.845 6.845 0 0 0-2.116-1.462.852.852 0 1 0-.7 1.554l.008.003a5.15 5.15 0 0 1 1.592 1.1.85.85 0 0 0 .608.255"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 2"
        />
        <path
          d="M16.131 9.072a.852.852 0 0 0 .603-1.455 9.923 9.923 0 0 0-2.263-1.709.852.852 0 0 0-.815 1.498 8.21 8.21 0 0 1 1.872 1.417c.16.16.377.25.603.249"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 3"
        />
        <path
          d="M16.461 3.489a.853.853 0 0 0-.884 1.457l.01.006a11.054 11.054 0 0 1 2.135 1.676.852.852 0 0 0 1.206-1.205A12.74 12.74 0 0 0 16.46 3.49"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 4"
        />
        <path
          d="M.25 6.629a.852.852 0 0 0 1.205 0 11.424 11.424 0 0 1 8.134-3.361c.865-.001 1.727.095 2.571.286l-.976 1.22A10.156 10.156 0 0 0 9.59 4.65a10.01 10.01 0 0 0-7.147 2.969A.852.852 0 1 0 3.65 8.82 8.317 8.317 0 0 1 9.59 6.354c.108 0 .216.004.324.009L8.77 7.79a6.875 6.875 0 0 0-4.148 2.016A.852.852 0 0 0 5.838 11c.297-.3.629-.562.99-.78l-2.56 3.2a.852.852 0 1 0 1.33 1.065l10.48-13.101A.853.853 0 0 0 14.748.32l-1.421 1.776A13.265 13.265 0 0 0 .25 5.423a.852.852 0 0 0 0 1.206"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 5"
        />
        <path
          d="M9.589 13.734a1.029 1.029 0 1 1 0 2.058 1.029 1.029 0 0 1 0-2.058"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 6"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h19.173v15.792H0V0z" data-name="Rectangle 1" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const wifiConnect = () => {
  return (
    <svg
      fill="#fff"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 407.31 407.31"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M203.652,241.068c-33.646,0-61.019,27.371-61.019,61.016c0,33.646,27.371,61.019,61.019,61.019 c33.645,0,61.018-27.371,61.018-61.019C264.669,268.439,237.296,241.068,203.652,241.068z M203.652,335.617 c-18.486,0-33.531-15.045-33.531-33.533c0-18.486,15.045-33.531,33.531-33.531c18.494,0,33.531,15.045,33.531,33.531 C237.183,320.572,222.146,335.617,203.652,335.617z"></path>{" "}
            <path d="M203.652,174.603c-40.578,0-78.728,15.81-107.42,44.503c-5.367,5.367-5.367,14.064,0,19.432 c2.686,2.686,6.2,4.027,9.717,4.027c3.518,0,7.033-1.342,9.717-4.027c23.5-23.506,54.75-36.45,87.986-36.45 c33.242,0,64.486,12.944,87.992,36.45c5.36,5.369,14.071,5.369,19.434,0c5.367-5.367,5.367-14.064,0-19.432 C282.383,190.411,244.236,174.603,203.652,174.603z"></path>{" "}
            <path d="M47.832,170.696c-5.369,5.368-5.369,14.064,0,19.433c5.361,5.368,14.064,5.368,19.434,0 c75.209-75.195,197.572-75.195,272.781,0c2.685,2.685,6.199,4.026,9.718,4.026c3.516,0,7.03-1.342,9.715-4.026 c5.369-5.368,5.369-14.064,0-19.433C273.56,84.778,133.75,84.778,47.832,170.696z"></path>{" "}
            <path d="M403.285,126.898c-53.328-53.326-124.223-82.691-199.633-82.691S57.346,73.572,4.027,126.898 c-5.369,5.368-5.369,14.065,0,19.434c5.366,5.368,14.069,5.368,19.432,0c48.135-48.134,112.123-74.639,180.193-74.639 s132.065,26.505,180.199,74.639c2.684,2.684,6.198,4.026,9.717,4.026c3.516,0,7.031-1.343,9.717-4.026 C408.652,140.963,408.652,132.266,403.285,126.898z"></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export const deleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.565" height="14.926">
      <path
        d="M7.283 0a3.776 3.776 0 0 0-3.606 2.67H.417a.418.418 0 1 0 0 .836h1.091l.51 9.195a2.361 2.361 0 0 0 2.352 2.224h5.826a2.363 2.363 0 0 0 2.354-2.224l.509-9.195h1.09-.001a.419.419 0 0 0 0-.835h-3.26A3.773 3.773 0 0 0 7.283 0Zm0 .836c1.235 0 2.286.76 2.72 1.834h-5.44A2.928 2.928 0 0 1 7.283.836Zm-4.938 2.67h9.875l-.508 9.149a1.514 1.514 0 0 1-1.518 1.434H4.368c-.81 0-1.473-.625-1.518-1.434l-.505-9.149ZM5.178 5.84a.419.419 0 0 0-.394.44l.282 5.081a.417.417 0 1 0 .834-.047l-.28-5.08a.417.417 0 0 0-.441-.394h-.001Zm4.208 0a.418.418 0 0 0-.439.395l-.282 5.08a.417.417 0 1 0 .834.046l.283-5.08h-.001a.415.415 0 0 0-.394-.44h-.001Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 10756"
      />
    </svg>
  );
};

function Connection({ setTab }) {
  const user = useSelector((value) => value?.user?.user);
  const [showConnection, setShowConnection] = useState(true);
  const [view, setView] = useState("connect");
  const [callSubmit, setCallSubmit] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [credentials, setCredentials] = useState({
    exchange: "",
    apiKey: "",
    secretKey: "",
  });
  const [showOtpPopUp, setShowOtpPopUp] = useState({
    show: false,
    buttonClick: "",
  });
  const [showDeleteApiKey, setShowDeleteApiKey] = useState({
    show: false,
    apiObj: null,
  });

  const [showDisConnectApiKey, setShowDisConnectApiKey] = useState({
    show: false,
    apiObj: null,
  });

  const [alredayConnect, setAlredayConnect] = useState(false);
  const [selectedConnectionType, setSelectedConnectionType] = useState("");
  const [differentExchange, setDifferentExchange] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  const loaders = useSelector((state) => state?.loader);
  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScroll(false);
  }, [scroll]);

  const liveUrl = true;
  const oauthBinance = async () => {
    if (
      user?.binanceCredentials?.apiKey &&
      user?.binanceCredentials?.secretKey
    ) {
      getUserCredentials("Binance", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }
      dispatch(setBinanceConnecting(true));
      const clientId = "39vZ9un1Fd";
      const redirect_uri = liveUrl
        ? "https%3A%2F%2Fmy.copyyy.it%2Fsignin"
        : "https%3A%2F%2Fdev.copyyy.it%2Fsignin";
      const scope = "user:openId,create:apikey";
      const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
      window.location.href = cUrl;
    }
  };

  const oauth = async () => {
    if (user?.bybitCredentials?.apiKey && user?.bybitCredentials?.secretKey) {
      getUserCredentials("ByBit", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }
      const clientId = "3ccf41551815af4";
      const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
      window.location.href = cUrl;
    }
  };

  const getUserCredentials = async (event, hint) => {
    let exchangeKey = "";
    if (event === "Binance") {
      exchangeKey = "binance";
    } else if (event === "ByBit") {
      exchangeKey = "bybit";
    }

    if (
      (event === "ByBit" && !user?.bybitCredentials?.apiKey) ||
      (event === "Binance" && !user?.binanceCredentials?.apiKey)
    ) {
      setCredentials((prev) => ({
        ...prev,
        apiKey: "",
        secretKey: "",
        exchange: event,
      }));
    } else {
      const updatedCredentials = {
        ...credentials,
        exchange: event,
      };
      setCredentials(updatedCredentials);

      dispatch(setLoading(true));
      const response = await api(
        "get",
        `binanceCredentials/index/${exchangeKey}`
      );
      dispatch(setLoading(false));

      if (response?.status) {
        let data = response?.data;
        if (data?.length === 3) {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        } else {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        }
      } else {
        setCredentials({
          apiKey: "",
          secretKey: "",
          exchange: event,
        });
      }
    }
  };

  useEffect(() => {
    if (callSubmit) {
      submit();
    }
  }, [callSubmit]);

  const switchToApiKeyView = () => {
    setScroll(true);
    setSelectedConnectionType("");
    setShowConnection((prevShowConnection) => !prevShowConnection);
    setView((prevView) => (prevView === "connect" ? "apiKeys" : "connect"));
    setCredentials({
      apiKey: "",
      secretKey: "",
      exchange: "",
    });
  };

  const submit = async () => {
    if (!credentials?.exchange) missingPopup("Exchange");
    else if (!credentials?.apiKey) missingPopup("API Key");
    else if (!credentials?.secretKey) missingPopup("Secret Key");
    else {
      dispatch(setResponseLoading(true));
      const response = await api("post", "binanceCredentials/create", {
        ...credentials,
      });

      if (response?.status) {
        dispatch(setResponseLoading(false));
        dispatch(updateUser(response?.data));
        dispatch(setShowConnectionSuccessModal(true));
        if (user?.firstVisit) {
          setTimeout(async () => {
            if (user?.role === "member") {
              dispatch(setCurrentTab("subscribe"));
              await api("post", "users/update", {
                onboardingStep: "subscribe",
              });
            }
            if (user?.role === "leader") {
              dispatch(setCurrentTab("payment"));
              await api("post", "users/update", {
                onboardingStep: "payment",
              });
            }
          }, 2000);
        }

        if (!callSubmit) {
          switchToApiKeyView();
        } else {
          setCallSubmit(false);
        }
      } else {
        setCallSubmit(false);
        dispatch(setResponseLoading(false));
        // toastify(response?.message);
        dispatch(setConnectionFailMessage(response?.message));
        dispatch(setShowConnectionFailModal(true));
      }
    }
  };

  const disconnect = async () => {
    dispatch(setLoading(true));

    let credentialToUpdate = {};
    if (user?.binanceCredentials?.isConnected) {
      credentialToUpdate = {
        binanceCredentials: {
          ...user?.binanceCredentials,
          isConnected: false,
          isConnectedSpot: false,
          isConnectedUsdFuture: false,
        },
        onboarding: {
          ...user.onboarding,
          connect: false,
        },
      };
    }

    if (user?.bybitCredentials?.isConnected) {
      credentialToUpdate = {
        bybitCredentials: {
          ...user?.bybitCredentials,
          isConnected: false,
          isConnectedSpot: false,
          isConnectedDerivatives: false,
        },
        onboarding: {
          ...user.onboarding,
          connect: false,
        },
      };
    }

    if (credentialToUpdate) {
      const response = await api("post", "users/update", credentialToUpdate);

      if (response?.status) {
        dispatch(updateUser(response?.data?.user));
        toastify("Exchange disconnected successfully.", "success");
      } else {
        toastify(response?.message);
      }
    }

    dispatch(setLoading(false));
  };

  const differentExchangeYesAction = () => {
    setDifferentExchange(false);
  };

  const handleExchangeChange = (selectedValue) => {
    setSelectedConnectionType(selectedValue?.value);
    setCredentials((prev) => ({
      ...prev,
      exchange: selectedValue.value,
    }));
    getUserCredentials(selectedValue.value);
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.className = "hidden-input";
    tempInput.focus();

    setTimeout(() => {
      tempInput?.remove();
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  };
  const handleErrorModal = () => {
    dispatch(setShowConnectionFailModal(false));
    dispatch(setConnectionFailMessage(""));
  };

  const fetchUserData = async () => {
    const myResponse = await api("get", "users/index");
    if (myResponse) {
      dispatch(updateUser(myResponse?.data?.user));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const callApi = async (buttonClick) => {
    if (showOtpPopUp?.buttonClick === "ByBit" || buttonClick === "ByBit") {
      user?.bybitCredentials?.isConnected ? disconnect() : oauth();
    } else if (
      showOtpPopUp?.buttonClick === "Binance" ||
      buttonClick === "Binance"
    ) {
      user?.binanceCredentials?.isConnected ? disconnect() : oauthBinance();
    } else if (showOtpPopUp?.buttonClick === "DeleteApiKey") {
      await api("post", "binanceCredentials/update", {
        ...showOtpPopUp.apiObj,
      });
      fetchUserData();
    } else {
      submit();
    }
  };

  return (
    <React.Fragment>
      {showConnection && view === "connect" && (
        <div className="connection pb-sm-50px">
          {loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal &&
            loaders?.connectionFailMessage ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : (
            <>
              {user?.firstVisit && (
                <div className="text-center">
                  {user?.memberCommunities[0]?.community?.name ? (
                    <p className="fs16 bold mb-21 text-center">
                      {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                    </p>
                  ) : (
                    <span className="mb-27 text-light">.</span>
                  )}
                </div>
              )}
              <div className="title">
                {user?.binanceCredentials?.isConnected ? (
                  <h3>Connected Trading Account</h3>
                ) : (
                  <h3>Connect Your Trading Account</h3>
                )}

                <p>
                  You will automatically copy trade using the trading account
                  you connect with. When connecting you’ll accept to receive buy
                  and sell signals to that particular account. Nothing else.
                </p>
              </div>
              <div className="connectionBlock">
                <div
                  className={`accountCard ${
                    user?.binanceCredentials?.isConnected ? "active" : ""
                  }`}
                >
                  <div className="account">
                    <img src={Binance} alt="binance" />
                    <div className="d-flex align-items-center">
                      <p>
                        API Status:
                        <span
                          className={
                            user?.binanceCredentials?.isConnectedUsdFuture
                              ? "cyan ms-1"
                              : "red ms-1"
                          }
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div className="accountButtonBox">
                    <button
                      className={`${
                        user?.bybitCredentials?.isConnected
                          ? "cursorNotAllowed"
                          : ""
                      }`}
                      onClick={() => {
                        if (user?.bybitCredentials?.isConnected) {
                          setAlredayConnect(true);
                        } else if (
                          user.firstVisit ||
                          !user?.binanceCredentials?.isConnected
                        ) {
                          callApi("Binance");
                        } else {
                          setShowDisConnectApiKey({
                            show: true,
                            buttonClick: "Binance",
                          });
                        }
                      }}
                    >
                      {user?.binanceCredentials?.isConnected
                        ? wifiDisconnect()
                        : wifiConnect()}
                      {user?.binanceCredentials?.isConnected
                        ? "Disconnect"
                        : "Connect"}
                    </button>
                    {user?.binanceCredentials?.isConnected && (
                      <button
                        className={"black"}
                        onClick={() => {
                          setShowDeleteApiKey({
                            show: true,
                            apiObj: {
                              binanceCredentials: {},
                              uniqueIds: {},
                            },
                          });
                        }}
                      >
                        {deleteIcon()}
                        Delete API
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={`accountCard ${
                    user?.bybitCredentials?.isConnected ? "active" : ""
                  }`}
                >
                  <div className="account">
                    <img src={BayBit} alt="ByBit" />
                    <div className="d-flex align-items-center">
                      <p>
                        API Status:
                        <span
                          className={
                            user?.bybitCredentials?.isConnectedDerivatives
                              ? "cyan ms-1"
                              : "red ms-1"
                          }
                        ></span>
                      </p>
                    </div>
                  </div>
                  <div className="accountButtonBox">
                    <button
                      className={`${
                        user?.binanceCredentials?.isConnected
                          ? "cursorNotAllowed"
                          : ""
                      }`}
                      onClick={() => {
                        if (user?.binanceCredentials?.isConnected) {
                          setAlredayConnect(true);
                        } else if (
                          user.firstVisit ||
                          !user?.bybitCredentials?.isConnected
                        ) {
                          callApi("ByBit");
                        } else {
                          setShowDisConnectApiKey({
                            show: true,
                            buttonClick: "ByBit",
                          });
                        }
                      }}
                    >
                      {user?.bybitCredentials?.isConnected
                        ? wifiDisconnect()
                        : wifiConnect()}
                      {user?.bybitCredentials?.isConnected
                        ? "Disconnect"
                        : "Connect"}
                    </button>

                    {user?.bybitCredentials?.isConnected && (
                      <button
                        className="black"
                        onClick={() => {
                          setShowDeleteApiKey({
                            show: true,
                            apiObj: {
                              bybitCredentials: {},
                            },
                          });
                        }}
                      >
                        {deleteIcon()}
                        Delete API
                      </button>
                    )}
                  </div>
                </div>
                <div className="apiInsted">
                  {!user?.binanceCredentials?.isConnected &&
                    !user?.bybitCredentials?.isConnected && (
                      <h3 onClick={switchToApiKeyView}>
                        &gt; Use API keys instead
                      </h3>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!showConnection && view === "apiKeys" && (
        <div className="connection pb-sm-50px">
          {loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : (
            <>
              {user?.firstVisit && (
                <div className="text-center">
                  {user?.memberCommunities[0]?.community?.name ? (
                    <p className="fs16 bold mb-21 text-center">
                      {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                    </p>
                  ) : (
                    <span className="mb-27 text-light">.</span>
                  )}
                </div>
              )}
              <div className="title">
                <h3>Connect Your Trading Account</h3>
                <p>
                  You will automatically copy trade using the trading account
                  you connect with. When connecting you’ll accept to receive buy
                  and sell signals to that particular account. Nothing else.
                </p>
              </div>
              <div className="connectionBlock">
                <div className="title mt-5 mb-3">
                  <h3>Select your exchange(s) to connect</h3>
                </div>
                <ExchangeConnects
                  onExchangeChange={handleExchangeChange}
                  credentials={credentials}
                />
                {selectedConnectionType && (
                  <>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          ref={inputRef}
                          type="text"
                          className="form-control"
                          id="apiKeys"
                          placeholder="API Key"
                          value={credentials?.apiKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              apiKey: e.target.value,
                            }))
                          }
                        />
                        <label for="apiKeys">Api Key</label>
                      </div>
                    </div>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="secretKey"
                          placeholder="Secret Key"
                          value={credentials?.secretKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              secretKey: e.target.value,
                            }))
                          }
                        />
                        <label for="secretKey">Secret Key</label>
                      </div>
                    </div>
                    {credentials?.exchange !== "ByBit" && (
                      <>
                        <p className="mt-4">
                          <strong>Important:</strong> your exchange requires you
                          to use our IP address for the API setup.{" "}
                        </p>
                        <div className="url p-0">
                          <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                            <input
                              type="text"
                              className="form-control fs14 regular"
                              value={
                                HOSTNAME.split(".")[0] === "my"
                                  ? "108.129.27.187"
                                  : "52.214.92.9"
                              }
                              disabled
                            />
                            <span className="position-absolute">
                              <img
                                className="cursor-pointer"
                                onClick={() => {
                                  const communityIPAddress =
                                    HOSTNAME.split(".")[0] === "my"
                                      ? "108.129.27.187"
                                      : "52.214.92.9";

                                  navigator.clipboard
                                    .writeText(communityIPAddress)
                                    .then(() => {
                                      toastify(
                                        "IP Address copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Clipboard write error:",
                                        error
                                      );
                                      toastify(
                                        "Failed to copy community link to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="apiInsted">
                  <h3 onClick={switchToApiKeyView}>&gt; Use Quick connect</h3>
                  {credentials.apiKey && credentials.secretKey && (
                    <button
                      className="btn cyan-btn fs13"
                      onClick={() => {
                        if (user.firstVisit) {
                          callApi();
                        } else {
                          setShowOtpPopUp({ show: true });
                        }
                      }}
                    >
                      {user?.firstVisit ? "Next" : "Save Changes"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {showDisConnectApiKey?.show && (
        <DeleteMember
          yesAction={() => {
            setShowOtpPopUp({
              show: true,
              buttonClick: showDisConnectApiKey.buttonClick,
            });

            setShowDisConnectApiKey({
              show: false,
              buttonClick: null,
            });
          }}
          noAction={() => {
            setShowDisConnectApiKey({
              show: false,
              buttonClick: null,
            });
          }}
          text="Are you sure you want to Disconnect?"
          subtext="If you do, you won’t be receiving further buy/sell signals and you’re responsible for any active trades."
        />
      )}
      {differentExchange && (
        <DeleteMember
          yesAction={differentExchangeYesAction}
          text="You need to connect again"
          subtext="We've switched exchange. Please connect and make sure you have the proper funds on the exchange. Your copy trade won't work until you reconnect."
          noneclass="d-none"
        />
      )}
      {alredayConnect && (
        <DeleteMember
          yesAction={() => {
            setAlredayConnect(false);
          }}
          text="You need to disconnect"
          subtext="you can’t add a 2nd exchange before you’ve disconnected current exchange"
          noneclass="d-none"
        />
      )}
      {showOtpPopUp?.show && (
        <UpdateAndVerifyAccountOTP
          setShowOtpPopUp={setShowOtpPopUp}
          callApi={callApi}
        />
      )}
      {showDeleteApiKey.show && (
        <DeleteMember
          yesAction={() => {
            setShowOtpPopUp({
              show: true,
              buttonClick: "DeleteApiKey",
              apiObj: showDeleteApiKey.apiObj,
            });

            setShowDeleteApiKey({
              show: false,
              apiObj: null,
            });
          }}
          noAction={() => {
            setShowDeleteApiKey({
              show: false,
              apiObj: null,
            });
          }}
          text="Are you Sure you want to delete you keys permanantly"
          subtext="Next time you’ll need to manually add your Api keys to connect your account"
        />
      )}
      <RenderLinks page="copyTradeConnect" />
    </React.Fragment>
  );
}

export default Connection;

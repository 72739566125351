import "./AddVipMembers.css";
import { Button, Modal } from "react-bootstrap";
import { toastify } from "../../../../helpers/toast/toastify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cross from "../../../../assets/images/cross.svg";
import DatePicker, { CalendarContainer } from "react-datepicker";
import FloatingDropdownWithArrow from "../../CommonComponents/FloatingDropdownWithArrow/FloatingDropdownWithArrow.jsx";
import FloatingTextFiled from "../../CommonComponents/FloatingTextFiled/FloatingTextFiled";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import memberAdded from "../../../../assets/images/memberAdded.png";
import useApi from "../../../../helpers/apiHelper/requestHelper.js";
import vipBellPng from "../../../../assets/images/vipBellPng.png";
import Viploader from "../../../../assets/images/Viploader.gif";
import BubbleSvgButton from "../../CommonComponents/BubbleSvgButton/BubbleSvgButton";

const DateSelector = ({ selectedDate, setSelectedDate }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const Container = ({ children, className }) => {
    return (
      <div className="calendarContainer">
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className="mamberDateSelectBox">
      <DatePicker
        calendarContainer={Container}
        className="form-control memberDropDown"
        icon={
          <svg
            height="5.083"
            viewBox="555.521 760 9.149 5.083"
            width="9.149"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M560.095 765.083 555.521 760h9.149l-4.575 5.083Z"
              data-name="Triangle"
              fill-rule="evenodd"
            />
          </svg>
        }
        minDate={tomorrow}
        onChange={(date) => setSelectedDate(date)}
        placeholderText="Select end date"
        selected={selectedDate}
        showIcon
      />
    </div>
  );
};

const AddVipMembers = ({ setIsNewVipAdded }) => {
  const [codeValidity, setCodeValidity] = useState("");
  const [email, setEmail] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [vipSubscription, setVipSubscription] = useState(true);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVipAllowed, setIsVipAllowed] = useState(false);
  const [isVipDisabled, setIsVipDisabled] = useState(false);
  const [showSuccessfullPopUp, setShowSuccessfullPopUp] = useState(false);
  const [warn, setWarn] = useState(false);

  const api = useApi();
  const user = useSelector((value) => value?.user?.user);

  useEffect(() => {
    const allSubscriptions = user?.leaderCommunity?.subscription || [];

    const hasVipSubscription = allSubscriptions.filter((sub) =>
      sub.name.endsWith("_Vip")
    )[0];

    setIsVipAllowed(hasVipSubscription?.isAllowed);

    if (!hasVipSubscription) {
      setIsVipDisabled(true);
      setVipSubscription(false);
    }
  }, [user]);

  const toggleVipAccess = async (isAllowed) => {
    const response = await api("post", "vipMember/changeVipAllowedStatus", {
      isAllowed,
    });

    setIsVipAllowed(isAllowed);
    toastify(response?.message);
  };

  const submitVipMemberRequest = async () => {
    setIsLoading(true);

    if (vipSubscription && !isVipAllowed) {
      setIsAddButtonClicked(true);
      setIsLoading(false);

      return setWarn(true);
    }

    if (!email) {
      setIsLoading(false);
      return toastify("Member email missing");
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setIsLoading(false);
      return toastify("Member email is not valid");
    }

    if (!codeValidity) {
      setIsLoading(false);
      return toastify("Select access time");
    }

    if (codeValidity === "Specific end date" && !endDate) {
      setIsLoading(false);
      return toastify("Date missing");
    }

    const comunityLeaderUrl = `${document.location.origin.replace(
      document.location.pathname,
      ""
    )}/community/${user?.leaderCommunity?.name?.split(" ")?.join("")}`;

    setIsAddButtonClicked(true);
    setIsNewVipAdded(true);

    try {
      const response = await api("post", "vipMember/createCode", {
        codeValidity,
        comunityLeaderUrl,
        email,
        endDate,
      });

      if (response.status) {
        setIsLoading(false);
        setIsNewVipAdded(false);
        setShowSuccessfullPopUp(true);

        if (!vipSubscription) {
          setIsVipDisabled(false);
          setVipSubscription(true);
        }
      }

      setCodeValidity("");
      setEmail("");
      setEndDate("");
    } catch (error) {
      console.error("🚀 -> submitVipMemberRequest -> error:", error);

      setIsAddButtonClicked(false);
      setIsLoading(false);

      toastify("Failed to add member. Please try again.");
    }
  };

  return (
    <div className="addVipMemberBox">
      <div className="row mb-31px align-items-center">
        <div className="col-lg-6 col-md-6 col-6">
          <p className="title">
            <span>Add VIP member</span>
            <InfoPopup
              contentItems={[
                { text: "Invite VIP Members", type: "heading" },
                {
                  text: "Turn this on to create a VIP subscription for your current lifetime(or just a longer access period) members at our cost price of $15/ month. If you define an end date for the VIP member then member will be offered to resubscribe at end of VIP membership. You can turn it off once you’ve onboarded all your VIP members without affecting their subscription.",
                  type: "paragraph",
                },
              ]}
            />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <div className="VipToggleBox mt-3 mt-md-0 mt-lg-0">
            <div
              className="VipToggle"
              style={{ cursor: isVipDisabled && "not-allowed" }}
            >
              <button
                className={`on ${isVipAllowed ? "active" : "inactive"}`}
                disabled={isVipDisabled}
                onClick={() => toggleVipAccess(true)}
                style={{ cursor: isVipDisabled && "not-allowed" }}
              >
                On
              </button>
              <button
                className={`off ${isVipAllowed ? "inactive" : "active"}`}
                disabled={isVipDisabled}
                onClick={() => toggleVipAccess(false)}
                style={{ cursor: isVipDisabled && "not-allowed" }}
              >
                Off
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12">
          <FloatingTextFiled
            className="VipMemberEmail fixedWidth1440"
            forLabel="VipMemberEmail"
            id="VipMemberEmail"
            label="VIP member email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="VIP member email"
            type="email"
            value={email}
          />
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="fixedWidth1440 mx-auto">
            <FloatingDropdownWithArrow
              className="mb-20px accessTimeDropDown"
              id="accessTimeDropDown"
              label="Select access time"
              options={["Lifetime", "Specific end date"]}
              selectedValue={codeValidity}
              setSelectedValue={setCodeValidity}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="fixedWidth1440 mx-auto">
            {codeValidity === "Specific end date" && (
              <DateSelector
                className="VipMemberDropdown"
                selectedDate={endDate}
                setSelectedDate={setEndDate}
              />
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12 fixedWidth1440 ms-auto">
          <BubbleSvgButton
            onClick={submitVipMemberRequest}
            text={"Add member"}
            type="button"
            variant="black"
            disabled={isLoading}
            justifyContent="center"
          />
        </div>
      </div>
      {isAddButtonClicked && (
        <div className="addMemberModall">
          <Modal
            backdrop="false"
            id="addMemberModall"
            onHide={() => {
              setIsAddButtonClicked(false);
              setShowSuccessfullPopUp(false);
            }}
            show
          >
            <Modal.Body>
              <div className="modalContent">
                {isLoading && (
                  <div className="LoadingBox">
                    <img alt="" src={Viploader} />
                  </div>
                )}

                {showSuccessfullPopUp && (
                  <>
                    <Button
                      className="closeBtn"
                      onClick={() => {
                        setIsAddButtonClicked(false);
                        setShowSuccessfullPopUp(false);
                      }}
                    >
                      <img alt="Close Modal" src={cross} />
                    </Button>
                    <div className="regBox success">
                      <div className="img-box">
                        <img alt="Success" src={memberAdded} />
                      </div>
                      <div className="content-box">
                        <p>
                          <span>VIP member has been added!</span>
                          <br />
                          Member has received a sign-up email with a unique VIP
                          code.
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {warn && (
                  <>
                    <Button
                      className="closeBtn"
                      onClick={() => {
                        setIsAddButtonClicked(false);
                        setWarn(false);
                      }}
                    >
                      <img alt="Close Modal" src={cross} />
                    </Button>
                    <div className="regBox">
                      <div className="img-box">
                        <img alt="Success" src={vipBellPng} />
                      </div>
                      <div className="content-box">
                        <p>
                          <span>VIP member wasn't added!</span>
                          <br />
                          You can't add VIP members before you turn on VIP
                          function.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AddVipMembers;

import "./FloatingDropdownWithArrow.css";
import { useEffect, useRef, useState } from "react";
import Arrow from "../../../../assets/images/rightAnglePath.svg";
import dropdownAngle from "../../../../assets/images/dropdownAngle.svg";

const FloatingDropdownWithArrow = ({
  className = "",
  id,
  label,
  options,
  selectedValue,
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => setIsOpen((prevState) => !prevState);

  const handleOptionSelect = (value) => {
    setIsOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      )
        setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`form-floating customDropdownComponent ${className}`}
      ref={dropdownRef}
    >
      <button
        className={`dropdownButton ${isOpen ? "active" : ""} ${
          selectedValue ? "has-value" : ""
        }`}
        onClick={handleDropdownToggle}
        ref={buttonRef}
      >
        <span
          className={`dropdown-selected ${!selectedValue ? "placeholder" : ""}`}
        >
          {selectedValue || `Select ${label}`}
        </span>
        <span
          className={`dropdownArrow ${isOpen && "rotate"}`}
          style={{ opacity: selectedValue && 1 }}
        >
          <img alt="" src={dropdownAngle} />
        </span>
      </button>
      <label
        className={`floating-label ${isOpen || selectedValue ? "active" : ""}`}
        htmlFor={id}
      >
        {label}
      </label>
      {isOpen && (
        <div className="dropdownContent">
          {options.map((option, index) => (
            <p
              className="dropdown-option"
              key={index}
              onClick={() => handleOptionSelect(option)}
            >
              <span className="span1">{option}</span>
              <span className="arrowspan">
                <img alt="" src={Arrow} />
              </span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloatingDropdownWithArrow;

import React, { useState, useRef, useEffect } from "react";
import "./AddMember.css";
import InfoIcon from "../../../../assets/images/teir-info-icon.png";
import { Form, Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import RightAngle from "../../../../assets/images/rightAnglePath.svg";
import Cross from "../../../../assets/images/green-corss.svg";
import vipBellPng from "../../../../assets/images/vipBellPng.png";
import ModalCross from "../../../../assets/images/cross.svg";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import VipArrow from "../../../../assets/images/vipArrow.svg";
import { toastify } from "../../../../helpers/toast/toastify";
import Loader from "../../../../assets/images/Viploader.gif";
import { useSelector } from "react-redux";

const AddMember = ({ setRefetch }) => {
  const [email, setEmail] = useState("");
  const [endDate, setEndDate] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [codeValidity, setCodeValidity] = useState("");
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showSuccessfullPopUp, setShowSuccessfullPopUp] = useState(false);
  const [clickAddButton, setClickAddButton] = useState(false);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const api = useApi();
  const dropdownRef = useRef(null);
  const user = useSelector((value) => value?.user?.user);

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleSelectOption = (option) => {
    setCodeValidity(option);
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const callApi = async () => {
    if (!email) {
      toastify("Member email missing");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      toastify("Member email is not valid");
      return;
    }
    if (!codeValidity) {
      toastify("Select access time");
      return;
    }
    if (codeValidity === "Specific end date" && !endDate) {
      toastify("Date missing");
      return;
    }
    setClickAddButton(true);

    const comunityLeaderUrl = `${document.location.origin.replace(
      document.location.pathname,
      ""
    )}/community/${user?.leaderCommunity?.name?.split(" ")?.join("")}`;

    try {
      await api("post", "vipMember/createCode", {
        email,
        codeValidity,
        endDate,
        comunityLeaderUrl,
      });

      setEmail("");
      setCodeValidity("");
      setEndDate("");
      setTimeout(() => {
        setShowSuccessfullPopUp(true);
        setRefetch((prev) => !prev);
      }, 1000);
    } catch (error) {
      console.error("Error creating VIP code:", error);
      toastify("Failed to add member. Please try again.");
    }
  };

  return (
    <div className="addMembeBox">
      <p className="title position-relative">
        Add VIP member
        <span
          className="infoIcon"
          onClick={() => setShowInfoPopup(true)} // Show popup on clicking info icon
        >
          <img src={InfoIcon} alt="Info" />
        </span>
        {showInfoPopup && (
          <div className="infoPopup">
            <button
              type="button"
              className="closePopupButton"
              onClick={() => setShowInfoPopup(false)} // Close popup on clicking cross icon
            >
              <img src={Cross} alt="Close" />
            </button>
            <p className="">
              <span className="mb-1 d-block bold">Adding VIP Members</span>
              If you have members who have already paid for a lifetime or a
              longer access period then you can grant them access at our cost
              price of $15.
              <br />
              <br />
              If you define an end date for the VIP member, then the member will
              be offered to resubscribe at the end of the VIP membership.
              <br />
            </p>
          </div>
        )}
      </p>

      <div className="mainMemberBox">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <Form>
              <Form.Group className="mb-4 mb-lg-0">
                <Form.Control
                  type="email"
                  value={email}
                  placeholder="VIP member email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Form>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div ref={dropdownRef} className="dropdown mb-4 mb-lg-0">
              <button
                className={`btn btn-secondary dropdown-toggle memberDropDown ${
                  codeValidity ? "selected" : ""
                }`}
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                {codeValidity || "Select access time"}{" "}
                <img
                  style={{ transform: showDropdown && "rotate(180deg)" }}
                  src={VipArrow}
                  alt="VipArrow"
                />
              </button>

              {showDropdown && (
                <ul className="dropdown-menu show">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectOption("Lifetime")}
                    >
                      <span>Lifetime</span>
                      <span>
                        <img src={RightAngle} alt="Right Angle" />
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectOption("Specific end date")}
                    >
                      <span>Specific end date</span>
                      <span>
                        <img src={RightAngle} alt="Right Angle" />
                      </span>
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            {codeValidity === "Specific end date" && (
              <div className="mamberDateSelectBox mb-4 mb-lg-0">
                <DatePicker
                  className="form-control memberDropDown"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={tomorrow}
                  placeholderText="Select end date"
                />
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div>
              <button
                type="button"
                onClick={callApi}
                className="btn btn-primary AddMemberButton"
              >
                Add member
              </button>
            </div>
          </div>
        </div>
      </div>

      {clickAddButton && (
        <div className="addMemberModal">
          <Modal
            show
            id="addMemberModal"
            onHide={() => {
              setClickAddButton(false);
              setShowSuccessfullPopUp(false);
            }}
            backdrop="false"
          >
            <Modal.Body>
              <div className="modalContent">
                {showSuccessfullPopUp ? (
                  <>
                    <Button
                      className="closeBtn"
                      onClick={() => {
                        setClickAddButton(false);
                        setShowSuccessfullPopUp(false);
                      }}
                    >
                      <img src={ModalCross} alt="Close Modal" />
                    </Button>
                    <div className="regBox">
                      <div className="img-box">
                        <img src={vipBellPng} alt="Success Bell" />
                      </div>
                      <div className="content-box">
                        <p>
                          <span>VIP member has been added!</span>
                          <br />
                          Member has received a sign-up email with a unique VIP
                          code.
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="LoadingBox">
                    <img src={Loader} alt="" />
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AddMember;

import "./NewSidebar.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BtmLogo from "../../../assets/images/logo_black.svg";
import BubbleSvgButton from "../CommonComponents/BubbleSvgButton/BubbleSvgButton";
import Cross from "../../../assets/images/cancelBlack.svg";

const NewSidebar = ({ items }) => {
  const [comunityName, setComunityName] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const navigate = useNavigate();
  const sidebarLinksRef = useRef(null);
  const user = useSelector((e) => e?.user?.user);

  const handleLinkClick = (path) => {
    setIsSidebarOpen(false);
    navigate(path);
  };

  const scrollSidebarToBottom = useCallback(() => {
    const sidebar = sidebarLinksRef.current;
    if (sidebar) {
      sidebar.scrollTo({
        behavior: "smooth",
        top: sidebar.scrollHeight,
      });
    }
  }, []);

  const toggleDropdown = (index) =>
    setOpenDropdown(openDropdown === index ? null : index);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useEffect(() => {
    if (user) {
      const communityName =
        user?.role === "leader"
          ? user?.leaderCommunity?.name
          : user?.memberCommunities[0]?.community?.name;
      setComunityName(communityName);
    }
  }, [user]);

  // useEffect(() => {
  //   if (openDropdown !== null) scrollSidebarToBottom();
  // }, [openDropdown, scrollSidebarToBottom]);

  return (
    <>
      <button className="ToggleBtn" onClick={toggleSidebar} type="button">
        <span></span>
      </button>
      <div className={`newSidebarBox ${isSidebarOpen ? "open" : ""}`}>
        <button
          className="ToggleBtn inner"
          onClick={toggleSidebar}
          type="button"
        >
          <img alt="" src={Cross} />
        </button>
        <div className="businessNameBox">
          <h2 className="businessName">{comunityName}</h2>
        </div>
        <div className="NewsidebarLinksBox" ref={sidebarLinksRef}>
          {items.map((item, index) => {
            const isDropdownOpen = openDropdown === index;

            return (
              <div key={index}>
                <BubbleSvgButton
                  isDropdown={item.isDropDown}
                  isDropdownOpen={isDropdownOpen}
                  onClick={() => {
                    if (item.isDropDown) {
                      toggleDropdown(index);
                    } else {
                      handleLinkClick(item.url);
                      toggleDropdown(openDropdown);
                    }
                  }}
                  svgIcon={item.icon}
                  svgPosition="left"
                  text={item.label}
                  variant="sidebarVarient"
                  type="button"
                  mainDivStyle={{ height: "auto" }}
                />

                {item.isDropDown && isDropdownOpen && (
                  <div className="dropdownMenu">
                    {item.subItems.map((subItem, subIndex) => (
                      <button
                        className="dropdownItem"
                        key={subIndex}
                        onClick={() => {
                          toggleDropdown(index);
                          handleLinkClick(subItem.url);
                        }}
                      >
                        {subItem.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="newSidebarBottomBox">
          <span className="divider"></span>
          <p className="PoweredBy">Powered by</p>
          <img alt="Copyyy.it" src={BtmLogo} />
        </div>
      </div>
    </>
  );
};

export default NewSidebar;

import "./NewCopyTrade.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AutoShare from "./AutoShare/AutoShare";
import CopyUrl from "./CopyUrl/CopyUrl";
import ExchangeConnection from "./ExchangeConnection/ExchangeConnection";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";

const NewCopyTrade = () => {
  const { currentTab } = useSelector((state) => state.links);

  useEffect(() => {
    let element = null;

    if (currentTab === "AutoShare")
      element = document.getElementById("AutoShare");
    if (currentTab === "ExchangeConnection")
      element = document.getElementById("ExchangeConnection");

    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, [currentTab]);

  return (
    <div className="NewCopyTradePage">
      <div className="container-fluid g-0">
        <h2 className="PageTitle text_GB fs20 mt-smLayout-30">
          Copy Trade Service
        </h2>
        <SubscriptionCard />
        <ExchangeConnection />
        <CopyUrl />
        <AutoShare />
      </div>
    </div>
  );
};

export default NewCopyTrade;

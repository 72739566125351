import "./ProfileDropdown.css";
import { clearLoadingState } from "../../../redux/loading/loadingSlice";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { storeUser } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../assets/images/rightAnglePath.svg";
import Copy from "../../../assets/images/Copy.svg";
import React, { useState, useEffect, useRef } from "react";

const ProfileDropdown = () => {
  const user = useSelector((e) => e?.user?.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [leaderCommunityName, setLeaderCommunityName] = useState("");
  const [onBoard, setOnBoard] = useState(false);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setIsOpen(false);
  };

  const handleOptionClick = () => setIsOpen(false);
  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLeaderCommunityName(user?.leaderCommunity?.name);
  }, [user]);

  useEffect(() => {
    if (onBoard) {
      if (user?.role === "leader") {
        navigate("/leader/copyTrade");
      } else if (user?.role === "member") {
        if (user?.firstVisit === true && user?.onboarding?.payment === true) {
          navigate("/member/tradeActive", { replace: true });
        } else {
          dispatch(setCurrentTab("introduction"));
          navigate("/member/copyTrade", { replace: true });
        }
      } else if (user?.role === "admin") {
        navigate("/admin/copyTrade");
      }
      setOnBoard(false);
    }
  }, [onBoard]);

  return (
    <div className="profile-dropdown" ref={dropdownRef}>
      <button
        className={`dropdown-toggle ${isOpen ? "active" : ""}`}
        onClick={toggleDropdown}
      >
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="21"
          height="21"
          viewBox="0 0 23.532 23.527"
        >
          <g data-name="Group 15961" clip-path="unset">
            <path
              d="M10.928.025C2.298.57-2.783 10.245 1.612 17.727c4.5 7.66 15.67 7.765 20.238.125C26.767 9.63 20.424-.574 10.928.025m9.525 17.1c-4.052 6.513-13.585 6.443-17.475-.178-3.903-6.643.662-14.94 8.285-15.374 8.122-.461 13.507 8.613 9.19 15.552"
              data-name="Path 10752"
              fill-rule="evenodd"
              fill="#000"
            />
            <path
              d="M11.472 5.256c3.285-.27 4.869 3.85 2.311 5.867 1.617.701 2.86 2.086 3.25 3.822.243 1.079.316 2.12-.754 2.735-1.337.768-4.707.688-6.286.622-2.51-.104-4.225-.633-3.439-3.568.438-1.633 1.67-2.95 3.21-3.61a3.345 3.345 0 0 1-1.25-2.14c-.268-1.838 1.102-3.575 2.958-3.728m-.043 1.59c-1.877.27-1.806 3.3.246 3.415 2.424.135 2.415-3.8-.246-3.416m.085 5.484c-1.865.135-3.59 1.764-3.594 3.69-.001.402.338.473.671.546 1.279.28 5.089.26 6.363-.043.227-.053.477-.105.63-.292l.036-.082c-.045-2.185-1.897-3.98-4.106-3.82"
              data-name="Path 10753"
              fill-rule="evenodd"
              fill="#000"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path d="M0 0h23.557v23.527H0V0z" data-name="Rectangle 2911" />
            </clipPath>
          </defs>
        </svg>
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li
            onClick={() => {
              handleOptionClick();
              navigate("newAccountSettings");
              if (user?.firstVisit) setOnBoard(true);
            }}
          >
            Account & Security
            <img alt="Arrow" src={Arrow} />
          </li>
          {user?.role === "leader" && (
            <>
              <li
                onClick={() => {
                  handleOptionClick();

                  navigate("memberListening");
                }}
              >
                User Management
                <img alt="Arrow" src={Arrow} />
              </li>
              <li
                className="copuurlLink"
                onClick={() => {
                  handleOptionClick();

                  const communityLink = `${document.location.origin.replace(
                    document.location.pathname,
                    ""
                  )}/community/${leaderCommunityName.split(" ").join("")}`;
                  navigator.clipboard
                    .writeText(communityLink)
                    .then(() =>
                      toastify("Community link copied to clipboard!", "success")
                    )
                    .catch((error) => {
                      console.error("🚀 -> ProfileDropdown -> error:", error);
                      return toastify(
                        "Failed to copy community link.",
                        "error"
                      );
                    });
                }}
              >
                Copy Trade URL
                <img alt="Copy" src={Copy} />
              </li>
              <li
                onClick={() => {
                  handleOptionClick();

                  if (user?.firstVisit) setOnBoard(true);
                  navigate("support");
                }}
              >
                Support
                <img alt="Arrow" src={Arrow} />
              </li>
            </>
          )}
          <li
            className="signoutLink"
            onClick={() => {
              handleOptionClick();

              dispatch(clearLoadingState());
              dispatch(storeUser({ token: null, user: null }));
              localStorage.clear();
              navigate("/login");
              window.scrollTo({ behavior: "instant", top: 0 });
            }}
          >
            Sign out
          </li>
        </ul>
      )}
    </div>
  );
};

export default ProfileDropdown;

import Payment from "../sharedPages/copyTrade/Payment";
import React from "react";

const BusinessPayment = () => {
  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="">
          <div className="mx-691 mx-auto">
            <Payment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPayment;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { LoginLayout } from "../layout/LoginLayout";
import AccountSettings from "../pages/sharedPages/accountSettings/AccountSettings";
import SubscriptionAndBilling from "../pages/SubscriptionAndBilling";
import App from "../pages/sharedPages/app/Index.js";
import AppPricing from "../pages/App/AppPricing.jsx";
import BusinessListening from "../pages/sharedPages/Business";
import BusinessPayment from "../pages/Business/BusinessPayment.jsx";
import BusinessReport from "../pages/Business/BusinessReport.jsx";
import ClosedTrades from "../pages/Business/ClosedTrades.jsx";
import CommunityDashboard from "../pages/sharedPages/community/CommunityDashboard";
import CommunityDashboardForSocialLogin from "../pages/sharedPages/community/testBinanceLoginformember.js";
import CopyTrade from "../pages/sharedPages/copyTrade/Index.js";
import CopyTradeCm from "../pages/sharedPages/copyTradeCm/Index.js";
import CreatePassword from "../pages/auth/createPassword";
import CreatePromo from "../pages/Promo/CreatePromo.jsx";
import CreateSubAccount from "../pages/sharedPages/CreateSubAccount.js";
import DeveloperScripts from "../pages/developerScriptRunPages.js";
import EmailGetter from "../pages/auth/emailGetter.js";
import ErrorPage from "../pages/errorPages/errorPage";
import ForgotPassword from "../pages/auth/forgotPassword";
import History from "../pages/Business/PayoutHistory.jsx";
import HowToGetPaid from "../pages/leader/LeaderGetPaid";
import LeaderCryptoPayment from "../pages/leader/LeaderCryptoPayment";
import LeaderDashboard2 from "../pages/leader/LeaderDashBoard2.js";
import LeaderExchange from "../pages/sharedPages/connect/LeaderExchange";
import LeaderSocialPlatforms from "../pages/leader/LeaderSocialPlatforms.js";
import LeaderSubscribers from "../pages/sharedPages/support/LeaderSubscribers";
import LeaderTrial from "../pages/leader/LeaderTrial";
import LiveTrade from "../pages/sharedPages/liveTrade/liveTrade.js";
import MemberDashboard2 from "../pages/member/MemberDashBoard2.js";
import MemberExchange from "../pages/sharedPages/connect/MemberExchange";
import MemberListening from "../pages/sharedPages/Member";
import Members from "../pages/sharedPages/members/Index.js";
import NewCopyTrade from "../pages/sharedPages/NewCopyTrade";
import NewCopyTradeCM from "../pages/sharedPages/NewCopyTradeCM/index.jsx";
import NewLayout from "../layout/NewLayout.jsx";
import NewSetting from "../pages/sharedPages/newSetting";
import OrderApp from "../pages/App/OrderApp.jsx";
import Payment from "../pages/sharedPages/copyTradeCm/Payment.js";
import Performance from "../pages/sharedPages/performance/Index.js";
import PerformanceCm from "../pages/sharedPages/performanceCm/Index.js";
import Promote from "../pages/App/PromoteApp.jsx";
import LeaderOnboardingUser from "../pages/onBoardingUser/onBoardingUser.jsx";
import React, { useState } from "react";
import ReferralCodes from "../pages/sharedPages/referralCodes/ReferralCodes";
import RiskManagement from "../pages/sharedPages/connect/RiskManagement";
import Signin from "../pages/auth/signin";
import SignupLeader from "../pages/auth/signupLeader";
import SignupMember from "../pages/auth/signupMember";
import SocialLoginTestScreen from "../pages/auth/socialLoginTestScreen.js";
import SubscriptionPlans from "../pages/sharedPages/subscriptionPlans/SubscriptionPlans";
import Support from "../pages/sharedPages/support/Support";
import TradeActive from "../pages/sharedPages/tradeActive/TradeActive";
import TradeHistory from "../pages/sharedPages/tradeHistory/TradeHistory";
import TradeSettings from "../pages/sharedPages/tradeSettings/TradeSettings";
import TradeSettingsCm from "../pages/sharedPages/tradeSettings/MemberTradeSettings.js";
import Trading from "../pages/sharedPages/performance/Trading.js";
import TradingListening from "../pages/sharedPages/Trading";
import VerifyEmail from "../pages/auth/verifyEmail";
import VerifyPayment from "../pages/sharedPages/VerifyPayment.js";
import VipScreen from "../pages/sharedPages/VipScreen/index.js";

export const MainRouting = () => {
  const [community, setCommunity] = useState(null);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/*login*/}
        <Route path="/login" element={<LoginLayout />}>
          <Route index element={<Signin />}></Route>
          <Route path="createPassword/:id" element={<CreatePassword />}></Route>
          <Route path="forgotPassword" element={<ForgotPassword />}></Route>
          <Route
            path="socialLoginTest"
            element={<SocialLoginTestScreen />}
          ></Route>
          <Route path="verifyEmail" element={<VerifyEmail />}></Route>
        </Route>

        <Route path="/signin" element={<LoginLayout />}>
          <Route index element={<Signin />}></Route>
          <Route path="createPassword" element={<CreatePassword />}></Route>
          <Route path="forgotPassword" element={<ForgotPassword />}></Route>
          <Route path="verifyEmail" element={<VerifyEmail />}></Route>
        </Route>

        <Route
          path="/leader"
          element={<NewLayout />}
          errorElement={<ErrorPage />}
        >
          <Route path="accountSettings" element={<AccountSettings />}></Route>
          <Route path="app" element={<App />}></Route>
          <Route
            path="businessListening"
            element={<BusinessListening />}
          ></Route>
          <Route path="connectExchange" element={<LeaderExchange />}></Route>
          <Route path="copyTrade" element={<CopyTrade />}></Route>
          <Route path="copyTrade/:id" element={<CopyTrade />}></Route>
          <Route path="cryptoPayment" element={<LeaderCryptoPayment />}></Route>
          <Route path="dashboard" element={<LeaderDashboard2 />} />
          <Route path="emailGetter" element={<EmailGetter />}></Route>
          <Route path="getPaid" element={<HowToGetPaid />}></Route>
          <Route path="getPaid/:id" element={<HowToGetPaid />}></Route>
          <Route path="liveTrade" element={<LiveTrade />}></Route>
          <Route path="memberListening" element={<MemberListening />}></Route>
          <Route path="members" element={<Members />}></Route>
          <Route path="newAccountSettings" element={<NewSetting />}></Route>
          <Route path="newCopyTrade" element={<NewCopyTrade />}></Route>
          <Route path="performance" element={<Performance />}></Route>
          <Route path="referralCodes" element={<ReferralCodes />}></Route>
          <Route path="social" element={<LeaderSocialPlatforms />}></Route>
          <Route path="subscribers" element={<LeaderSubscribers />}></Route>
          <Route
            path="subscriptionPlans"
            element={<SubscriptionPlans />}
          ></Route>
          <Route path="support" element={<Support />}></Route>
          <Route path="tradeActive" element={<TradeActive />}></Route>
          <Route path="tradeHistory" element={<TradeHistory />}></Route>
          <Route path="tradeSettings" element={<TradeSettings />}></Route>
          <Route path="tradingListening" element={<TradingListening />}></Route>
          <Route path="trialPeriod" element={<LeaderTrial />}></Route>
          <Route path="vipMembers" element={<VipScreen />}></Route>

          <Route path="appPricing" element={<AppPricing />}></Route>
          <Route path="closedTrade" element={<ClosedTrades />}></Route>
          {/* <Route path="createPromo" element={<CreatePromo />}></Route> */}
          <Route path="orderApp" element={<OrderApp />}></Route>
          <Route path="overview" element={<Trading />}></Route>
          <Route path="payment" element={<BusinessPayment />}></Route>
          <Route path="payoutHistory" element={<History />}></Route>
          <Route path="promoteApp" element={<Promote />}></Route>
          <Route path="reports" element={<BusinessReport />}></Route>
          {/* <Route
            path="onboardingUser"
            element={<LeaderOnboardingUser />}
          ></Route> */}
        </Route>

        <Route
          path="/member"
          element={<NewLayout />}
          errorElement={<ErrorPage />}
        >
          <Route path="closedTrade" element={<ClosedTrades />}></Route>
          <Route path="accountSettings" element={<AccountSettings />}></Route>
          <Route path="newAccountSettings" element={<NewSetting />}></Route>
          <Route path="connectExchange" element={<MemberExchange />}></Route>
          <Route
            path="copyTrade"
            element={
              <CopyTradeCm community={community} setCommunity={setCommunity} />
            }
          ></Route>
          <Route
            path="subscriptionAndBilling"
            element={<SubscriptionAndBilling setCommunity={setCommunity} />}
          ></Route>
          <Route
            path="copyTrade/:id"
            element={
              <CopyTradeCm community={community} setCommunity={setCommunity} />
            }
          ></Route>
          <Route path="copyTrade2" element={<NewCopyTradeCM />}></Route>
          <Route path="createSubAccount" element={<CreateSubAccount />}></Route>
          <Route path="dashboard" element={<MemberDashboard2 />}></Route>
          <Route path="dashboard2" element={<MemberDashboard2 />}></Route>
          <Route path="emailGetter" element={<EmailGetter />}></Route>
          <Route path="liveTrade" element={<LiveTrade />}></Route>
          <Route path="performance" element={<PerformanceCm />}></Route>
          <Route
            path="riskManagement/:exchange"
            element={<RiskManagement />}
          ></Route>
          <Route
            path="stripePaymentScreen"
            element={
              <Payment community={community} setCommunity={setCommunity} />
            }
          ></Route>
          <Route
            path="subscriptionPlans"
            element={<SubscriptionPlans />}
          ></Route>
          <Route
            path="subscriptionPlans/:id"
            element={<SubscriptionPlans />}
          ></Route>
          <Route path="support" element={<Support />}></Route>
          <Route path="tradeActive" element={<TradeActive />}></Route>
          <Route path="tradeHistory" element={<TradeHistory />}></Route>
          <Route path="tradeSettings" element={<TradeSettingsCm />}></Route>
        </Route>

        <Route path="*" element={<Navigate to="/login" />} />
        <Route
          path="/community/:communityName"
          element={<CommunityDashboard />}
        />
        <Route
          path="/community/test/:communityName"
          element={<CommunityDashboardForSocialLogin />}
        />
        <Route path="/developerPage" element={<DeveloperScripts />} />
        <Route path="/signup" element={<SignupLeader />} />
        <Route path="/verifyPayment/:id" element={<VerifyPayment />} />
        <Route path="member/signup" element={<SignupMember />} />
      </>
    )
  );
  return <RouterProvider router={router} />;
};

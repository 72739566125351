import "./NewCopyTradeCM.css";
import ExchangeConnection from "../NewCopyTrade/ExchangeConnection/ExchangeConnection";
import React from "react";
import HowItWorks from "./HowItWorks/HowItWorks";

const NewCopyTradeCM = () => {
  return (
    <div className="NewCopyTradePageCM">
      <div className="container-fluid g-0">
        <h2 className="PageTitle text_GB fs20 mt-smLayout-30">
          Copy Trading Service
        </h2>
        <ExchangeConnection />
        <div className="spacer"></div>
        <HowItWorks />
      </div>
    </div>
  );
};

export default NewCopyTradeCM;

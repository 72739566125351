import React, { useEffect, useState } from "react";
import "./CopyUrl.css";
import InfoPopup from "../../CommonComponents/InfoPopup/InfoPopup";
import { toastify } from "../../../../helpers/toast/toastify";
import copyIcon from "../../../../assets/images/Copy.svg";
import { useSelector } from "react-redux";

const CopyUrl = () => {
  const [leaderCommunityName, setLeaderCommunityName] = useState("");
  const user = useSelector((value) => value?.user?.user);

  useEffect(() => {
    setLeaderCommunityName(user?.leaderCommunity?.name);
  }, [user]);

  return (
    <>
      <div className="copyUrlBox">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <p className="title">Copy Trade Service URL</p>
            <p className="subTitle">
              Sign up page for your community
              <InfoPopup
                contentItems={[
                  { type: "heading", text: "Copy Trade Service URL" },
                  {
                    type: "paragraph",
                    text: "This is your unique landing page where your members can sign up.",
                  },
                ]}
              />
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="UrlBox">
              <div className="UrlBoxInner">
                <input
                  type="text"
                  className="setformfontsize14"
                  value={`${document.location.origin.replace(
                    document.location.pathname,
                    ""
                  )}/community/${leaderCommunityName.split(" ").join("")}`}
                  disabled
                />
                <span className="copyIcon">
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      // Construct the community link using template literals
                      const communityLink = `${document.location.origin.replace(
                        document.location.pathname,
                        ""
                      )}/community/${leaderCommunityName.split(" ").join("")}`;

                      // Attempt to copy the link to the clipboard
                      navigator.clipboard
                        .writeText(communityLink)
                        .then(() => {
                          // Success: Show a toast notification
                          toastify(
                            "Community link copied to clipboard!",
                            "success"
                          );
                        })
                        .catch((error) => {
                          // Error: Handle clipboard write failure and show a toast notification
                          console.error("Clipboard write error:", error);
                          toastify(
                            "Failed to copy community link to clipboard.",
                            "error"
                          );
                        });
                    }}
                    src={copyIcon}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyUrl;

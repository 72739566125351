import React, { useState, useEffect, useRef } from "react";
import "./MemberList.css";
import { Table } from "react-bootstrap";
import searchIcon from "../../../../assets/images/search-icon.svg";
import Action from "../../../../assets/images/tierAction.svg";
import Copy from "../../../../assets/images/NewCopyIcon.svg";
import CustomPagination from "../../../../components/CustomPagination";
import useApi from "../../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../../helpers/toast/toastify";
import { Form, Modal, Button } from "react-bootstrap";
import RightAngle from "../../../../assets/images/rightAnglePath.svg";
import VipArrow from "../../../../assets/images/vipArrow.svg";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import vipCheckMark from "../../../../assets/images/vipCheckMark.svg";
import CancelSvg from "../../../../assets/images/cancel.svg";
import { useSelector } from "react-redux";
import ModalCross from "../../../../assets/images/cross.svg";
import vipBellPng from "../../../../assets/images/vipBellPng.png";

const MemberList = ({ refetch }) => {
  const [vipMembers, setVipMembers] = useState([]);
  const [copyVipMembers, setCopyVipMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [editEmailIndex, setEditEmailIndex] = useState(null);
  const [editExpiryDateIndex, setEditExpiryDateIndex] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchByEmail, setSearchByEmail] = useState("");
  const [showDeleteModel, setShowDeleteModel] = useState({
    show: false,
    apiObj: {},
  });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const user = useSelector((value) => value?.user?.user);

  const api = useApi();
  const dropdownRef = useRef(null);
  const expiryDropDownRef = useRef(null);
  const searchDropDownRef = useRef(null);

  const fetchAllVipMembers = async () => {
    const res = await api("get", "vipMember/allVipMembers");
    setVipMembers(res?.data?.vipMembers);
    setCopyVipMembers(res?.data?.vipMembers);
  };
  useEffect(() => {
    fetchAllVipMembers();
  }, [refetch]);

  useEffect(() => {
    if (searchByEmail === "") {
      setVipMembers(copyVipMembers);
    } else {
      const filteredMembers = vipMembers.filter(
        (member) =>
          member.email.toLowerCase().startsWith(searchByEmail.toLowerCase()) // Change to startsWith
      );

      setVipMembers(filteredMembers);
    }
  }, [searchByEmail]);

  const copyVipCode = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toastify("Vip code copied to clipboard!", "success");
      })
      .catch((error) => {
        toastify("Failed to copy vip code to clipboard.", "error");
      });
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdownIndex(null);
    }

    if (
      expiryDropDownRef.current &&
      !expiryDropDownRef.current.contains(e.target) &&
      !isMobile
    ) {
      setEditExpiryDateIndex(null);
      setShowDatePicker(false);
    }

    if (
      searchDropDownRef.current &&
      !searchDropDownRef.current.contains(e.target)
    ) {
      setShowSearch(false);
      setSearchByEmail("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const updateVipMember = async (recordId, updateFields) => {
    setOpenDropdownIndex(null);
    setEditEmailIndex(null);

    const comunityLeaderUrl = `${document.location.origin.replace(
      document.location.pathname,
      ""
    )}/community/${user?.leaderCommunity?.name?.split(" ")?.join("")}`;

    const res = await api("post", "vipMember/updateVipMember", {
      recordId,
      updateFields,
      comunityLeaderUrl,
    });
    fetchAllVipMembers();
    toastify(`${res?.message}`, "success");
  };

  const handleEmailEdit = (index, currentEmail) => {
    setOpenDropdownIndex(null);
    setEditEmailIndex(index);
    setNewEmail(currentEmail);
  };

  const showExpiryDate = (recordId, expiryDateValue) => {
    return (
      <div ref={expiryDropDownRef}>
        {!showDatePicker && (
          <div className="dropdown mb-3 mb-lg-0">
            <button
              className={`btn btn-secondary dropdown-toggle memberDropDown selected`}
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              {expiryDateValue}
              <img
                style={{ transform: showDropdown && "rotate(180deg)" }}
                src={VipArrow}
                alt="VipArrow"
              />
            </button>

            <ul className="dropdown-menu show">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setEditExpiryDateIndex(null);
                    updateVipMember(recordId, {
                      codeValidity: "Lifetime",
                      endDate: null,
                    });
                  }}
                >
                  <span>Lifetime</span>
                  <span>
                    <img src={RightAngle} alt="Right Angle" />
                  </span>
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setShowDatePicker(true);
                  }}
                >
                  <span>Specific end date</span>
                  <span>
                    <img src={RightAngle} alt="Right Angle" />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        )}

        {showDatePicker && (
          <div className="mamberDateSelectBox mb-3 mb-lg-0">
            <DatePicker
              className="form-control memberDropDown"
              onChange={(date) => {
                setEditExpiryDateIndex(null);
                setShowDatePicker(false);
                updateVipMember(recordId, {
                  codeValidity: "Specific end date",
                  endDate: date,
                });
              }}
              minDate={tomorrow}
              placeholderText="Select end date"
              autoFocus={true}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="mainMemberListBox">
        <div className="headingBox">
          <div>
            <p className="title">VIP members</p>
          </div>
          <div className="pe-lg-3 pe-md-3 pe-0">
            <div className="custom-dropdown z-index-fix d-flex">
              <button
                className="dropdown-toggle"
                onClick={() => {
                  setShowSearch(!showSearch);
                }}
              >
                <img
                  className="filter-icon"
                  alt="searchIcons"
                  src={searchIcon}
                />
              </button>

              {showSearch && (
                <div className="dropdown-menu" ref={searchDropDownRef}>
                  <div className="p-3">
                    {copyVipMembers?.length > 0 ? (
                      <div className="tab-contents">
                        <input
                          className="form-control filterInput"
                          type="text"
                          placeholder="Search email"
                          value={searchByEmail}
                          onChange={(e) => {
                            setSearchByEmail(e.target.value);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="ps-2 pe-sm-5 pe-2 py-3 text-center">
                        <h6 className="bold">No Subscribers</h6>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tableBox">
          <Table responsive>
            <thead>
              <tr>
                <th className="emailTh">Email</th>
                <th className="dateTh">Expiry Date</th>
                <th className="codeTh">VIP code</th>
                <th className="statusTH">Status</th>
                <th className="actionTh"></th>
              </tr>
            </thead>
            <tbody>
              {vipMembers
                ?.filter((e, index) => {
                  if (index < page * 10 && index > page * 10 - 11) {
                    return e;
                  }
                })
                ?.map((member, index) => {
                  const isSpecificEndDate =
                    member?.codeValidity === "Specific end date";
                  const isEndDateExpired =
                    isSpecificEndDate && new Date(member?.endDate) < new Date();
                  const isCodeUsed = member?.isUsed;

                  const formattedDate = new Date(member.endDate);
                  const day = formattedDate
                    .getDate()
                    .toString()
                    .padStart(2, "0"); // Ensures 2-digit day
                  const month = (formattedDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0"); // Ensures 2-digit month
                  const year = formattedDate.getFullYear();

                  const endDateColumnValue = `${day}.${month}.${year}`;

                  return (
                    <tr key={index}>
                      <td>
                        <div className="editEmail">
                          <p className="title only-for-sm">Email</p>
                          {editEmailIndex === index ? (
                            <Form onSubmit={(e) => e.preventDefault()}>
                              <Form.Group className="mb-3 mb-lg-0">
                                <Form.Control
                                  type="email"
                                  value={newEmail}
                                  placeholder="VIP member email"
                                  onChange={(e) => setNewEmail(e.target.value)}
                                  autoFocus
                                />
                              </Form.Group>
                              <div className="mt-2 d-flex d-lg-none d-md-none">
                                <button
                                  type="button"
                                  size="sm"
                                  className="btn btn-primary UpdateButton"
                                  onClick={() =>
                                    updateVipMember(member._id, {
                                      email: newEmail,
                                    })
                                  }
                                >
                                  Save
                                </button>
                                <button
                                  size="sm"
                                  className="ms-2 btn btn-primary CancelButton"
                                  onClick={() => setEditEmailIndex(null)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Form>
                          ) : (
                            <p className="mail">{member?.email}</p>
                          )}
                        </div>
                        <div className="combineBox mt-2 only-for-sm">
                          <div className="combineBoxInner">
                            <p className="title">Expiry date</p>
                            {editExpiryDateIndex === index ? (
                              showExpiryDate(member?._id, member?.codeValidity)
                            ) : (
                              <p className="date">
                                {member?.codeValidity === "Specific end date"
                                  ? endDateColumnValue
                                  : member?.codeValidity}
                              </p>
                            )}
                          </div>
                          <div>
                            <p className="title">VIP Code</p>
                            <p className="code">
                              {member?.vipCode}
                              <button
                                className="copyBtn"
                                type="button"
                                onClick={() => {
                                  copyVipCode(member?.vipCode);
                                }}
                              >
                                <img src={Copy} alt="" />
                              </button>
                            </p>
                          </div>
                          <div>
                            <p className="title">Status</p>
                            <span className={`codeStatus`}>
                              {isEndDateExpired ? (
                                <div className="imgContainer">
                                  <img src={CancelSvg} alt="Expired" />
                                </div>
                              ) : isCodeUsed ? (
                                <div className="imgContainer">
                                  <img src={vipCheckMark} alt="Used" />
                                </div>
                              ) : (
                                <p className="pending">Pending</p>
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="dateTd">
                        <div className="editExpiryDate">
                          {editExpiryDateIndex === index ? (
                            showExpiryDate(member?._id, member?.codeValidity)
                          ) : (
                            <p className="date">
                              {member?.codeValidity === "Specific end date"
                                ? endDateColumnValue
                                : member?.codeValidity}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className={`codeTd`}>
                        <p className="code">
                          <span className="number-w">{member?.vipCode}</span>
                          <button
                            className="copyBtn"
                            type="button"
                            onClick={() => {
                              copyVipCode(member?.vipCode);
                            }}
                          >
                            <img src={Copy} alt="" />
                          </button>
                        </p>
                      </td>
                      <td
                        className={`${
                          editEmailIndex === index ? "py-1" : ""
                        } statusTd`}
                      >
                        <div className="VipStatus">
                          {editEmailIndex === index ? (
                            <button
                              type="button"
                              size="sm"
                              className="btn UpdateButtonDeskTop d-none d-lg-block d-md-block"
                              onClick={() =>
                                updateVipMember(member._id, {
                                  email: newEmail,
                                })
                              }
                            >
                              Save
                            </button>
                          ) : (
                            <span className={`codeStatus`}>
                              {isEndDateExpired ? (
                                <div className="imgContainer">
                                  <img src={CancelSvg} alt="Expired" />
                                </div>
                              ) : isCodeUsed ? (
                                <div className="imgContainer">
                                  <img src={vipCheckMark} alt="Used" />
                                </div>
                              ) : (
                                <p className="pending">Pending</p>
                              )}
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        className={`${
                          editEmailIndex === index ? "py-1" : ""
                        } pe-lg-3 pe-md-3 pe-0`}
                      >
                        {editEmailIndex === index ? (
                          <button
                            size="sm"
                            className="ms-2 btn CancelButtonDeskTop d-none d-lg-block d-md-block"
                            onClick={() => setEditEmailIndex(null)}
                          >
                            Cancel
                          </button>
                        ) : (
                          <div className="actionDropdown text-end">
                            <button
                              className="actionBtn"
                              type="button"
                              onClick={() => {
                                setOpenDropdownIndex(index);
                                setEditEmailIndex(null);
                                setEditExpiryDateIndex(null);
                              }}
                            >
                              <img src={Action} alt="" />
                            </button>

                            {/* Action Dropdown Menu */}
                            {openDropdownIndex === index && (
                              <div className="dropdown-menu" ref={dropdownRef}>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleEmailEdit(index, member?.email)
                                  }
                                >
                                  Edit email
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    setOpenDropdownIndex(null);
                                    setEditExpiryDateIndex(index);
                                  }}
                                >
                                  Change end date
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    setShowDeleteModel({
                                      show: true,
                                      apiObj: {
                                        memberId: member?._id,
                                      },
                                    });
                                  }}
                                >
                                  Delete member
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {vipMembers?.length > 10 && (
            <div className="d-flex justify-content-end mb-0 mt-41">
              <CustomPagination
                setPage={setPage}
                page={page}
                filtered={vipMembers}
                items={vipMembers}
              />
            </div>
          )}
        </div>

        <div className="deleteModal">
          <Modal
            show={showDeleteModel?.show}
            id="deleteModal"
            onHide={() => {
              setShowDeleteModel({
                show: false,
                apiObj: {},
              });
            }}
            backdrop="false"
          >
            <Modal.Body>
              <div className="modalContent">
                <Button
                  className="closeBtn"
                  onClick={() => {
                    setShowDeleteModel({
                      show: false,
                      apiObj: {},
                    });
                  }}
                >
                  <img src={ModalCross} alt="Close Modal" />
                </Button>
                <div className="regBox">
                  <div className="img-box">
                    <img src={vipBellPng} alt="Success Bell" />
                  </div>
                  <div className="content-box">
                    <p>
                      <span>Do you want to delete member?</span>
                      <br />
                      Member will be deleted. You can add member any time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex algn-items-center justify-content-center w-100 buttonDiv">
                <button
                  type="button"
                  onClick={() => {
                    updateVipMember(showDeleteModel?.apiObj?.memberId, {
                      isDelete: true,
                    });
                    setShowDeleteModel({
                      show: false,
                      apiObj: {},
                    });
                  }}
                  className="DeleteMemberButton"
                >
                  Yes Delete
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MemberList;

import React, { useState, useRef } from "react";
import "./SearchBar.css";

const SearchBar = ({ onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 400); // Matches the animationTime from original code
  };

  const handleSvgClick = () => {
    // Focus the input element when SVG is clicked
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // No need to call handleFocus() as the input's onFocus will trigger it
  };

  const styleObj = {
    width: "100%",
    borderRadius: "10px",
    // transform: "scale(1.18)",
  };

  return (
    <div className="SearchContainer">
      <div>
        <input
          ref={inputRef}
          style={isFocused ? styleObj : {}}
          autoComplete="off"
          id="input"
          type="search"
          placeholder="Search here ..."
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <svg
          className={`${isFocused ? "hidden" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 22.082 22.058"
          onClick={handleSvgClick}
        >
          <path
            d="m21.886 20.765-4.487-4.45c3.347-3.88 3.156-9.736-.533-13.425C14.965.95 12.416 0 9.906 0S4.85.95 2.91 2.89a9.87 9.87 0 0 0 0 13.995 9.907 9.907 0 0 0 6.998 2.89 9.779 9.779 0 0 0 6.427-2.395l4.488 4.487c.114.115.304.19.494.19a.75.75 0 0 0 .532-.228c.305-.266.305-.76.038-1.064ZM3.974 15.783c-1.597-1.56-2.434-3.69-2.434-5.895 0-2.206.875-4.336 2.434-5.933 1.597-1.597 3.69-2.434 5.933-2.434 2.244 0 4.335.875 5.933 2.434 1.559 1.597 2.434 3.69 2.434 5.933 0 2.244-.875 4.335-2.434 5.933a8.504 8.504 0 0 1-5.933 2.434 8.38 8.38 0 0 1-5.933-2.472Z"
            fill="#000"
            fill-rule="evenodd"
            data-name="Path 10841"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchBar;
